let slider_banners = document.getElementsByClassName('slider-banners');
if (slider_banners){
    for (let i = 0; i < slider_banners.length; i++){
        let swiperBanners;
        (async function () {
            const slider = slider_banners[i].querySelector('.js-slider-banners');
            if (!slider) return;
            const { default: Swiper } = await import("../libs/Swiper");
            swiperBanners = new Swiper(slider, {
                loop: true,
                slidesPerView: 1,
                spaceBetween: 0,
                navigation: {
                  prevEl: slider.querySelector('.swiper-button-prev'),
                  nextEl: slider.querySelector('.swiper-button-next'),
                },
            });
        })();
        window.onload = function(){
            let arrow_next_circle = slider_banners[i].querySelector('.js-arrow-circle');
            let arrow_next = slider_banners[i].querySelector('.swiper-button-next');
            let arrow_prev = slider_banners[i].querySelector('.swiper-button-prev');
            let start_stroke = 160;
            let stroke = start_stroke;
            let interval = 31.25;
            let timerId = setInterval(function() {
                stroke = stroke - 1;
                if (stroke != 0){
                    arrow_next_circle.style.cssText = `
                    stroke-dashoffset: ${stroke};
                    `;
                }
                else{
                    arrow_next.click();
                    stroke = start_stroke;
                }
            }, interval);
            arrow_next.addEventListener("mouseover", function(){
                arrow_next_circle.style.cssText = `
                stroke-dashoffset: ${start_stroke};
                `;
                clearInterval(timerId);
            });
            arrow_next.addEventListener("mouseout", function(){
                clearInterval(timerId);
                stroke = start_stroke;
                timerId = setInterval(function() {
                    stroke = stroke - 1;
                    if (stroke != 0){
                        arrow_next_circle.style.cssText = `
                        stroke-dashoffset: ${stroke};
                        `;
                    }
                    else{
                        arrow_next.click();
                        stroke = start_stroke;
                    }
                }, interval);
            });
            arrow_prev.onclick = function(){
                clearInterval(timerId);
                stroke = start_stroke;
                timerId = setInterval(function() {
                    stroke = stroke - 1;
                    if (stroke != 0){
                        arrow_next_circle.style.cssText = `
                        stroke-dashoffset: ${stroke};
                        `;
                    }
                    else{
                        arrow_next.click();
                        stroke = start_stroke;
                    }
                }, interval);
            }
        }
    }
}