let js_mobile_menu = document.getElementsByClassName('js-mobile-menu');
if (js_mobile_menu){
    let mobile_menu = document.getElementById('mobile-menu');
    for(let i = 0; i < js_mobile_menu.length; i++){
        js_mobile_menu[i].onclick = function(){
            if (mobile_menu.classList.contains('active')){
                js_mobile_menu[i].classList.remove('active');
                mobile_menu.classList.remove('active');
            }
            else{
                js_mobile_menu[i].classList.add('active');
                mobile_menu.classList.add('active');
            }
        }
    }
    window.addEventListener('resize',() => {
        let widthWindow = document.body.clientWidth;
        if (widthWindow > 1024){
            for(let i = 0; i < js_mobile_menu.length; i++){
                js_mobile_menu[i].classList.remove('active');
                mobile_menu.classList.remove('active');
            }
        }
    });
}