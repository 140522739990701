let marquee = document.getElementsByClassName('marquee');
if (marquee){
    function fun_marquee(){
        for(let i = 0; i < marquee.length; i++){
            let marquee_childs = marquee[i].children;
            let width_marquee_child = marquee_childs[0].clientWidth;
            let step = width_marquee_child / 3000;
            let stroke = 0;
            let points = [];
            let point = 1;
            for(let j = 0; j < marquee_childs.length; j++){
                points.push(marquee_childs[j].getBoundingClientRect()['x']);
            }
            let check = 0;
            let timerId = setInterval(function() {
                stroke -= step;
                if (points[point] <= stroke * -1){
                    point++;
                    stroke = 0;
                    let parentElement = marquee[i];
                    let decoding = marquee[i].children[0].getAttribute('decoding');
                    let src = marquee[i].children[0].getAttribute('src');
                    let alt = marquee[i].children[0].getAttribute('alt');
                    marquee[i].removeChild(marquee[i].children[0]);
                    let newElement = document.createElement('img');
                    newElement.setAttribute('decoding', decoding);
                    newElement.setAttribute('src', src);
                    newElement.setAttribute('alt', alt);
                    parentElement.appendChild(newElement);
                    clearInterval(timerId);
                    if (check == 0){
                        check = 1;
                        setTimeout(() => {
                            fun_marquee();
                        }, 10);
                    }
                }
                marquee[i].style.cssText = `
                    transform: translate(${stroke}px, 0);
                `;
            }, 1);
        }
    }
    fun_marquee();
}