let content_rooms = document.getElementsByClassName('content-rooms');
if (content_rooms){
    for (let i = 0; i < content_rooms.length; i++){
        let swiperRooms;
        (async function () {
            const js_swiper_counter = content_rooms[i].querySelector('.js-swiper-counter');
            const slider = content_rooms[i].querySelector('.js-slider-rooms');
            if (!slider) return;
            const { default: Swiper } = await import("../libs/Swiper");
            swiperRooms = new Swiper(slider, {
                loop: false,
                slidesPerView: 1,
                spaceBetween: 50,
                navigation: {
                    prevEl: slider.parentNode.parentNode.querySelector('.swiper-button-prev'),
                    nextEl: slider.parentNode.parentNode.querySelector('.swiper-button-next'),
                },
                on: {
                    slideChangeTransitionEnd: function(){
                        let swiper_slides = slider.querySelectorAll('.swiper-slide');
                        let index_active_slide = 0;
                        for (let j = 0; j < swiper_slides.length; j++){
                            if (swiper_slides[j].classList.contains('swiper-slide-active')){
                                index_active_slide = j + 1;
                                break;
                            }
                        }
                        js_swiper_counter.innerHTML = index_active_slide;
                    }
                }
            });
        })();
    }
}