import "./main.scss";
import "swiper/css"

import "htmx.org"

import "./js/alpine";
import "./js/modal";

import "./js/base/header";
import "./js/base/map";
import "./js/base/marquee";
import "./js/base/single";

import "./js/blocks/slider-banners";
import "./js/blocks/content-gallery";
import "./js/blocks/content-informations";
import "./js/blocks/content-rooms";
import "./js/blocks/content-reviews";