let content_room_top = document.getElementsByClassName('content-room-top');
if (content_room_top){
    for (let i = 0; i < content_room_top.length; i++){
        let swiperRoom;
        (async function () {
            const slider = content_room_top[i].querySelector('.js-slider-room-top');
            if (!slider) return;
            const { default: Swiper } = await import("../libs/Swiper");
            swiperRoom = new Swiper(slider, {
                loop: true,
                slidesPerView: 1,
                spaceBetween: 0,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                autoplay: {
                    delay: 3000,
                },
            });
        })();
    }
}