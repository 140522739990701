let content_gallery = document.getElementsByClassName('content-gallery');
if (content_gallery){
    for (let i = 0; i < content_gallery.length; i++){
        let swiperGallery_top;
        let swiperGallery_bottom;
        (async function () {
            const slider_top = content_gallery[i].querySelector('.js-slider-gallery-top');
            if (!slider_top) return;
            const { default: Swiper } = await import("../libs/Swiper");
            swiperGallery_top = new Swiper(slider_top, {
                loop: false,
                slidesPerView: 1,
                spaceBetween: 0,
                navigation: {
                    prevEl: slider_top.parentNode.querySelector('.swiper-button-prev'),
                    nextEl: slider_top.parentNode.querySelector('.swiper-button-next'),
                },
                on: {
                    slideChangeTransitionEnd: function(){
                        let swiper_slides = slider_top.querySelectorAll('.swiper-slide');
                        let index_active_slide = 0;
                        for (let j = 0; j < swiper_slides.length; j++){
                            if (swiper_slides[j].classList.contains('swiper-slide-active')){
                                index_active_slide = j + 1;
                                break;
                            }
                        }
                        let js_swiper_counter = slider_top.parentNode.querySelector('.js-swiper-counter');
                        let count_slides = js_swiper_counter.getAttribute('count_slide');
                        let prev_arrow = slider_top.parentNode.querySelector('.swiper-button-prev');
                        let next_arrow = slider_top.parentNode.querySelector('.swiper-button-next');
                        if (index_active_slide > 0){
                            let number_slide = index_active_slide;
                            js_swiper_counter.innerHTML = number_slide;
                            if (number_slide == 1){
                                prev_arrow.classList.add('no_active');
                            }
                            else{
                                prev_arrow.classList.remove('no_active');
                            }
                            if (number_slide == count_slides){
                                next_arrow.classList.add('no_active');
                            }
                            else{
                                next_arrow.classList.remove('no_active');
                            }
                        }
                    }
                }
            });
            const slider_bottom = content_gallery[i].querySelector('.js-slider-gallery-bottom');
            if (!slider_bottom) return;
            swiperGallery_bottom = new Swiper(slider_bottom, {
                loop: false,
                slidesPerView: 1,
                spaceBetween: 10,
            });
            swiperGallery_top.controller.control = swiperGallery_bottom;
            swiperGallery_bottom.controller.control = swiperGallery_top;
        })();
    }
}