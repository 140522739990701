let content_informations = document.getElementsByClassName('content-informations');
if (content_informations){
    let widthWindow = document.body.clientWidth;
    if (widthWindow > 1024){
        for(let i = 0; i < content_informations.length; i++){
            let content_inner_text = content_informations[i].querySelector('.content-inner-text');
            let content_inner_image = content_informations[i].querySelector('.content-inner-image');
            if (content_inner_text && content_inner_image){
                let transform = 'calc(' + content_inner_text.clientHeight + 'px + clamp(40px, 3.125vw, 60px))';
                content_inner_image.style.height = 'calc(100% + ' + transform + ')';
                content_informations[i].style.cssText = `
                margin-bottom: calc(${transform} + clamp(140px, 10.9375vw, 210px));
                `;
            }
        }
    }
    window.addEventListener('resize',() => {
        let widthWindow = document.body.clientWidth;
        if (widthWindow > 1024){
            for(let i = 0; i < content_informations.length; i++){
                content_inner_text = content_informations[i].querySelector('.content-inner-text');
                content_inner_image = content_informations[i].querySelector('.content-inner-image');
                if (content_inner_text && content_inner_image){
                    transform = 'calc(' + content_inner_text.clientHeight + 'px + clamp(40px, 3.125vw, 60px))';
                    content_inner_image.style.height = 'calc(100% + ' + transform + ')';
                    content_informations[i].style.cssText = `
                    margin-bottom: calc(${transform} + clamp(140px, 10.9375vw, 210px));
                    `;
                }
            }
        }
    });
}