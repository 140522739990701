let content_reviews = document.getElementsByClassName('content-reviews');
if (content_reviews){
    for(let i = 0; i < content_reviews.length; i++){
        let swiperReviews_src;
        (async function () {
            const js_swiper_counter = content_reviews[i].querySelector('.js-swiper-counter');
            const slider_src = content_reviews[i].querySelector('.js-slider-reviews-scr');
            if (!slider_src) return;
            const { default: Swiper } = await import("../libs/Swiper");
            swiperReviews_src = new Swiper(slider_src, {
                loop: false,
                slidesPerView: 1,
                spaceBetween: 0,
                navigation: {
                    prevEl: slider_src.parentNode.querySelector('.swiper-button-prev'),
                    nextEl: slider_src.parentNode.querySelector('.swiper-button-next'),
                },
                on: {
                    slideChangeTransitionEnd: function(){
                        let swiper_slides = slider_src.querySelectorAll('.swiper-slide');
                        let index_active_slide = 0;
                        for (let j = 0; j < swiper_slides.length; j++){
                            if (swiper_slides[j].classList.contains('swiper-slide-active')){
                                index_active_slide = j + 1;
                                break;
                            }
                        }
                        js_swiper_counter.innerHTML = index_active_slide;
                    }
                }
            });
        })();
    }
}