import { LngLat, YMap, YMapMarkerProps } from "@yandex/ymaps3-types";

interface IMapConstructorProps {
  container: HTMLElement;
  center?: LngLat;
  zoom?: number;
}

export default class Map {
  container: HTMLElement;
  ymap?: YMap | undefined;
  constructor({ container, center, zoom }: IMapConstructorProps) {
    if (!container) throw new Error("Контейнер не определён");

    this.container = container;
    this.createMap(center, zoom);
  }

  private static key = "ca4ab438-ce96-431f-9eb5-8441c2a35bb3";
  private static ymapIsReady = false;
  private static isLoading = false;
  private static loader: Promise<string>;

  static loadAPI() {
    if (!this.isLoading && !this.ymapIsReady) {
      this.loader = this.startLoad();
      return this.loader;
    } else return this.loader;
  }

  private static startLoad() {
    return new Promise<string>((resolve) => {
      this.isLoading = true;
      if (Map.ymapIsReady) {
        this.isLoading = false;
        resolve("loaded");
        return;
      }

      const script = document.createElement("script");
      script.src = `https://api-maps.yandex.ru/v3/?apikey=${this.key}&lang=ru_RU`;
      script.addEventListener("load", async () => {
        await ymaps3.ready;
        Map.ymapIsReady = true;
        this.isLoading = false;
        resolve("loaded");
      });
      document.body.append(script);
    });
  }

  static createDefaultMarker() {
    const marker = document.createElement("div");
    marker.innerHTML = `
			<svg viewBox="0 0 51 74" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25.5 0C11.4386 0 0 11.618 0 25.9C0 45.325 25.5 74 25.5 74C25.5 74 51 45.325 51 25.9C51 19.0309 48.3134 12.4431 43.5312 7.58593C38.749 2.72874 32.263 0 25.5 0ZM25.5 16.65C26.696 16.65 27.8802 16.8893 28.9852 17.3541C30.0901 17.819 31.094 18.5003 31.9397 19.3593C32.7854 20.2182 33.4562 21.2379 33.9139 22.3602C34.3716 23.4824 34.6071 24.6853 34.6071 25.9C34.6071 27.1147 34.3716 28.3176 33.9139 29.4398C33.4562 30.5621 32.7854 31.5818 31.9397 32.4407C31.094 33.2997 30.0901 33.981 28.9852 34.4459C27.8802 34.9107 26.696 35.15 25.5 35.15C23.0846 35.15 20.7682 34.1754 19.0603 32.4407C17.3524 30.706 16.3929 28.3533 16.3929 25.9C16.3929 23.4467 17.3524 21.094 19.0603 19.3593C20.7682 17.6246 23.0846 16.65 25.5 16.65Z"/>
      </svg>`;
    marker.className = "default-map-marker";

    return marker;
  }

  createMap(center = [37.617635, 55.755814] as LngLat, zoom = 15) {
    this.ymap = new ymaps3.YMap(
      this.container,
      {
        location: {
          center,
          zoom,
        },
      },
      [
        new ymaps3.YMapDefaultSchemeLayer({
          customization: [
            {
                "tags": "country",
                "elements": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ad9885"
                    },
                    {
                        "zoom": 0,
                        "opacity": 0.8
                    },
                    {
                        "zoom": 1,
                        "opacity": 0.8
                    },
                    {
                        "zoom": 2,
                        "opacity": 0.8
                    },
                    {
                        "zoom": 3,
                        "opacity": 0.8
                    },
                    {
                        "zoom": 4,
                        "opacity": 0.8
                    },
                    {
                        "zoom": 5,
                        "opacity": 1
                    },
                    {
                        "zoom": 6,
                        "opacity": 1
                    },
                    {
                        "zoom": 7,
                        "opacity": 1
                    },
                    {
                        "zoom": 8,
                        "opacity": 1
                    },
                    {
                        "zoom": 9,
                        "opacity": 1
                    },
                    {
                        "zoom": 10,
                        "opacity": 1
                    },
                    {
                        "zoom": 11,
                        "opacity": 1
                    },
                    {
                        "zoom": 12,
                        "opacity": 1
                    },
                    {
                        "zoom": 13,
                        "opacity": 1
                    },
                    {
                        "zoom": 14,
                        "opacity": 1
                    },
                    {
                        "zoom": 15,
                        "opacity": 1
                    },
                    {
                        "zoom": 16,
                        "opacity": 1
                    },
                    {
                        "zoom": 17,
                        "opacity": 1
                    },
                    {
                        "zoom": 18,
                        "opacity": 1
                    },
                    {
                        "zoom": 19,
                        "opacity": 1
                    },
                    {
                        "zoom": 20,
                        "opacity": 1
                    },
                    {
                        "zoom": 21,
                        "opacity": 1
                    }
                ]
            },
            {
                "tags": "country",
                "elements": "geometry.outline",
                "stylers": [
                    {
                        "color": "#d4ccc4"
                    },
                    {
                        "zoom": 0,
                        "opacity": 0.15
                    },
                    {
                        "zoom": 1,
                        "opacity": 0.15
                    },
                    {
                        "zoom": 2,
                        "opacity": 0.15
                    },
                    {
                        "zoom": 3,
                        "opacity": 0.15
                    },
                    {
                        "zoom": 4,
                        "opacity": 0.15
                    },
                    {
                        "zoom": 5,
                        "opacity": 0.15
                    },
                    {
                        "zoom": 6,
                        "opacity": 0.25
                    },
                    {
                        "zoom": 7,
                        "opacity": 0.5
                    },
                    {
                        "zoom": 8,
                        "opacity": 0.47
                    },
                    {
                        "zoom": 9,
                        "opacity": 0.44
                    },
                    {
                        "zoom": 10,
                        "opacity": 0.41
                    },
                    {
                        "zoom": 11,
                        "opacity": 0.38
                    },
                    {
                        "zoom": 12,
                        "opacity": 0.35
                    },
                    {
                        "zoom": 13,
                        "opacity": 0.33
                    },
                    {
                        "zoom": 14,
                        "opacity": 0.3
                    },
                    {
                        "zoom": 15,
                        "opacity": 0.28
                    },
                    {
                        "zoom": 16,
                        "opacity": 0.25
                    },
                    {
                        "zoom": 17,
                        "opacity": 0.25
                    },
                    {
                        "zoom": 18,
                        "opacity": 0.25
                    },
                    {
                        "zoom": 19,
                        "opacity": 0.25
                    },
                    {
                        "zoom": 20,
                        "opacity": 0.25
                    },
                    {
                        "zoom": 21,
                        "opacity": 0.25
                    }
                ]
            },
            {
                "tags": "region",
                "elements": "geometry.fill",
                "stylers": [
                    {
                        "zoom": 0,
                        "color": "#c2b1a3",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 1,
                        "color": "#c2b1a3",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 2,
                        "color": "#c2b1a3",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 3,
                        "color": "#c2b1a3",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 4,
                        "color": "#c2b1a3",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 5,
                        "color": "#c2b1a3",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 6,
                        "color": "#c2b1a3",
                        "opacity": 1
                    },
                    {
                        "zoom": 7,
                        "color": "#c2b1a3",
                        "opacity": 1
                    },
                    {
                        "zoom": 8,
                        "color": "#ad9885",
                        "opacity": 1
                    },
                    {
                        "zoom": 9,
                        "color": "#ad9885",
                        "opacity": 1
                    },
                    {
                        "zoom": 10,
                        "color": "#ad9885",
                        "opacity": 1
                    },
                    {
                        "zoom": 11,
                        "color": "#ad9885",
                        "opacity": 1
                    },
                    {
                        "zoom": 12,
                        "color": "#ad9885",
                        "opacity": 1
                    },
                    {
                        "zoom": 13,
                        "color": "#ad9885",
                        "opacity": 1
                    },
                    {
                        "zoom": 14,
                        "color": "#ad9885",
                        "opacity": 1
                    },
                    {
                        "zoom": 15,
                        "color": "#ad9885",
                        "opacity": 1
                    },
                    {
                        "zoom": 16,
                        "color": "#ad9885",
                        "opacity": 1
                    },
                    {
                        "zoom": 17,
                        "color": "#ad9885",
                        "opacity": 1
                    },
                    {
                        "zoom": 18,
                        "color": "#ad9885",
                        "opacity": 1
                    },
                    {
                        "zoom": 19,
                        "color": "#ad9885",
                        "opacity": 1
                    },
                    {
                        "zoom": 20,
                        "color": "#ad9885",
                        "opacity": 1
                    },
                    {
                        "zoom": 21,
                        "color": "#ad9885",
                        "opacity": 1
                    }
                ]
            },
            {
                "tags": "region",
                "elements": "geometry.outline",
                "stylers": [
                    {
                        "color": "#d4ccc4"
                    },
                    {
                        "zoom": 0,
                        "opacity": 0.15
                    },
                    {
                        "zoom": 1,
                        "opacity": 0.15
                    },
                    {
                        "zoom": 2,
                        "opacity": 0.15
                    },
                    {
                        "zoom": 3,
                        "opacity": 0.15
                    },
                    {
                        "zoom": 4,
                        "opacity": 0.15
                    },
                    {
                        "zoom": 5,
                        "opacity": 0.15
                    },
                    {
                        "zoom": 6,
                        "opacity": 0.25
                    },
                    {
                        "zoom": 7,
                        "opacity": 0.5
                    },
                    {
                        "zoom": 8,
                        "opacity": 0.47
                    },
                    {
                        "zoom": 9,
                        "opacity": 0.44
                    },
                    {
                        "zoom": 10,
                        "opacity": 0.41
                    },
                    {
                        "zoom": 11,
                        "opacity": 0.38
                    },
                    {
                        "zoom": 12,
                        "opacity": 0.35
                    },
                    {
                        "zoom": 13,
                        "opacity": 0.33
                    },
                    {
                        "zoom": 14,
                        "opacity": 0.3
                    },
                    {
                        "zoom": 15,
                        "opacity": 0.28
                    },
                    {
                        "zoom": 16,
                        "opacity": 0.25
                    },
                    {
                        "zoom": 17,
                        "opacity": 0.25
                    },
                    {
                        "zoom": 18,
                        "opacity": 0.25
                    },
                    {
                        "zoom": 19,
                        "opacity": 0.25
                    },
                    {
                        "zoom": 20,
                        "opacity": 0.25
                    },
                    {
                        "zoom": 21,
                        "opacity": 0.25
                    }
                ]
            },
            {
                "tags": {
                    "any": "admin",
                    "none": [
                        "country",
                        "region",
                        "locality",
                        "district",
                        "address"
                    ]
                },
                "elements": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ad9885"
                    },
                    {
                        "zoom": 0,
                        "opacity": 0.5
                    },
                    {
                        "zoom": 1,
                        "opacity": 0.5
                    },
                    {
                        "zoom": 2,
                        "opacity": 0.5
                    },
                    {
                        "zoom": 3,
                        "opacity": 0.5
                    },
                    {
                        "zoom": 4,
                        "opacity": 0.5
                    },
                    {
                        "zoom": 5,
                        "opacity": 0.5
                    },
                    {
                        "zoom": 6,
                        "opacity": 1
                    },
                    {
                        "zoom": 7,
                        "opacity": 1
                    },
                    {
                        "zoom": 8,
                        "opacity": 1
                    },
                    {
                        "zoom": 9,
                        "opacity": 1
                    },
                    {
                        "zoom": 10,
                        "opacity": 1
                    },
                    {
                        "zoom": 11,
                        "opacity": 1
                    },
                    {
                        "zoom": 12,
                        "opacity": 1
                    },
                    {
                        "zoom": 13,
                        "opacity": 1
                    },
                    {
                        "zoom": 14,
                        "opacity": 1
                    },
                    {
                        "zoom": 15,
                        "opacity": 1
                    },
                    {
                        "zoom": 16,
                        "opacity": 1
                    },
                    {
                        "zoom": 17,
                        "opacity": 1
                    },
                    {
                        "zoom": 18,
                        "opacity": 1
                    },
                    {
                        "zoom": 19,
                        "opacity": 1
                    },
                    {
                        "zoom": 20,
                        "opacity": 1
                    },
                    {
                        "zoom": 21,
                        "opacity": 1
                    }
                ]
            },
            {
                "tags": {
                    "any": "admin",
                    "none": [
                        "country",
                        "region",
                        "locality",
                        "district",
                        "address"
                    ]
                },
                "elements": "geometry.outline",
                "stylers": [
                    {
                        "color": "#d4ccc4"
                    },
                    {
                        "zoom": 0,
                        "opacity": 0.15
                    },
                    {
                        "zoom": 1,
                        "opacity": 0.15
                    },
                    {
                        "zoom": 2,
                        "opacity": 0.15
                    },
                    {
                        "zoom": 3,
                        "opacity": 0.15
                    },
                    {
                        "zoom": 4,
                        "opacity": 0.15
                    },
                    {
                        "zoom": 5,
                        "opacity": 0.15
                    },
                    {
                        "zoom": 6,
                        "opacity": 0.25
                    },
                    {
                        "zoom": 7,
                        "opacity": 0.5
                    },
                    {
                        "zoom": 8,
                        "opacity": 0.47
                    },
                    {
                        "zoom": 9,
                        "opacity": 0.44
                    },
                    {
                        "zoom": 10,
                        "opacity": 0.41
                    },
                    {
                        "zoom": 11,
                        "opacity": 0.38
                    },
                    {
                        "zoom": 12,
                        "opacity": 0.35
                    },
                    {
                        "zoom": 13,
                        "opacity": 0.33
                    },
                    {
                        "zoom": 14,
                        "opacity": 0.3
                    },
                    {
                        "zoom": 15,
                        "opacity": 0.28
                    },
                    {
                        "zoom": 16,
                        "opacity": 0.25
                    },
                    {
                        "zoom": 17,
                        "opacity": 0.25
                    },
                    {
                        "zoom": 18,
                        "opacity": 0.25
                    },
                    {
                        "zoom": 19,
                        "opacity": 0.25
                    },
                    {
                        "zoom": 20,
                        "opacity": 0.25
                    },
                    {
                        "zoom": 21,
                        "opacity": 0.25
                    }
                ]
            },
            {
                "tags": {
                    "any": "landcover",
                    "none": "vegetation"
                },
                "stylers": [
                    {
                        "hue": "#d7cfc6"
                    }
                ]
            },
            {
                "tags": "vegetation",
                "elements": "geometry",
                "stylers": [
                    {
                        "zoom": 0,
                        "color": "#c2b6a8",
                        "opacity": 0.1
                    },
                    {
                        "zoom": 1,
                        "color": "#c2b6a8",
                        "opacity": 0.1
                    },
                    {
                        "zoom": 2,
                        "color": "#c2b6a8",
                        "opacity": 0.1
                    },
                    {
                        "zoom": 3,
                        "color": "#c2b6a8",
                        "opacity": 0.1
                    },
                    {
                        "zoom": 4,
                        "color": "#c2b6a8",
                        "opacity": 0.1
                    },
                    {
                        "zoom": 5,
                        "color": "#c2b6a8",
                        "opacity": 0.1
                    },
                    {
                        "zoom": 6,
                        "color": "#c2b6a8",
                        "opacity": 0.2
                    },
                    {
                        "zoom": 7,
                        "color": "#d7cfc6",
                        "opacity": 0.3
                    },
                    {
                        "zoom": 8,
                        "color": "#d7cfc6",
                        "opacity": 0.4
                    },
                    {
                        "zoom": 9,
                        "color": "#d7cfc6",
                        "opacity": 0.6
                    },
                    {
                        "zoom": 10,
                        "color": "#d7cfc6",
                        "opacity": 0.8
                    },
                    {
                        "zoom": 11,
                        "color": "#d7cfc6",
                        "opacity": 1
                    },
                    {
                        "zoom": 12,
                        "color": "#d7cfc6",
                        "opacity": 1
                    },
                    {
                        "zoom": 13,
                        "color": "#d7cfc6",
                        "opacity": 1
                    },
                    {
                        "zoom": 14,
                        "color": "#dbd4cc",
                        "opacity": 1
                    },
                    {
                        "zoom": 15,
                        "color": "#e0d9d2",
                        "opacity": 1
                    },
                    {
                        "zoom": 16,
                        "color": "#e0d9d2",
                        "opacity": 1
                    },
                    {
                        "zoom": 17,
                        "color": "#e0d9d2",
                        "opacity": 1
                    },
                    {
                        "zoom": 18,
                        "color": "#e0d9d2",
                        "opacity": 1
                    },
                    {
                        "zoom": 19,
                        "color": "#e0d9d2",
                        "opacity": 1
                    },
                    {
                        "zoom": 20,
                        "color": "#e0d9d2",
                        "opacity": 1
                    },
                    {
                        "zoom": 21,
                        "color": "#e0d9d2",
                        "opacity": 1
                    }
                ]
            },
            {
                "tags": "park",
                "elements": "geometry",
                "stylers": [
                    {
                        "zoom": 0,
                        "color": "#d7cfc6",
                        "opacity": 0.1
                    },
                    {
                        "zoom": 1,
                        "color": "#d7cfc6",
                        "opacity": 0.1
                    },
                    {
                        "zoom": 2,
                        "color": "#d7cfc6",
                        "opacity": 0.1
                    },
                    {
                        "zoom": 3,
                        "color": "#d7cfc6",
                        "opacity": 0.1
                    },
                    {
                        "zoom": 4,
                        "color": "#d7cfc6",
                        "opacity": 0.1
                    },
                    {
                        "zoom": 5,
                        "color": "#d7cfc6",
                        "opacity": 0.1
                    },
                    {
                        "zoom": 6,
                        "color": "#d7cfc6",
                        "opacity": 0.2
                    },
                    {
                        "zoom": 7,
                        "color": "#d7cfc6",
                        "opacity": 0.3
                    },
                    {
                        "zoom": 8,
                        "color": "#d7cfc6",
                        "opacity": 0.4
                    },
                    {
                        "zoom": 9,
                        "color": "#d7cfc6",
                        "opacity": 0.6
                    },
                    {
                        "zoom": 10,
                        "color": "#d7cfc6",
                        "opacity": 0.8
                    },
                    {
                        "zoom": 11,
                        "color": "#d7cfc6",
                        "opacity": 1
                    },
                    {
                        "zoom": 12,
                        "color": "#d7cfc6",
                        "opacity": 1
                    },
                    {
                        "zoom": 13,
                        "color": "#d7cfc6",
                        "opacity": 1
                    },
                    {
                        "zoom": 14,
                        "color": "#dbd4cc",
                        "opacity": 1
                    },
                    {
                        "zoom": 15,
                        "color": "#e0d9d2",
                        "opacity": 1
                    },
                    {
                        "zoom": 16,
                        "color": "#e0d9d2",
                        "opacity": 0.9
                    },
                    {
                        "zoom": 17,
                        "color": "#e0d9d2",
                        "opacity": 0.8
                    },
                    {
                        "zoom": 18,
                        "color": "#e0d9d2",
                        "opacity": 0.7
                    },
                    {
                        "zoom": 19,
                        "color": "#e0d9d2",
                        "opacity": 0.7
                    },
                    {
                        "zoom": 20,
                        "color": "#e0d9d2",
                        "opacity": 0.7
                    },
                    {
                        "zoom": 21,
                        "color": "#e0d9d2",
                        "opacity": 0.7
                    }
                ]
            },
            {
                "tags": "national_park",
                "elements": "geometry",
                "stylers": [
                    {
                        "zoom": 0,
                        "color": "#d7cfc6",
                        "opacity": 0.1
                    },
                    {
                        "zoom": 1,
                        "color": "#d7cfc6",
                        "opacity": 0.1
                    },
                    {
                        "zoom": 2,
                        "color": "#d7cfc6",
                        "opacity": 0.1
                    },
                    {
                        "zoom": 3,
                        "color": "#d7cfc6",
                        "opacity": 0.1
                    },
                    {
                        "zoom": 4,
                        "color": "#d7cfc6",
                        "opacity": 0.1
                    },
                    {
                        "zoom": 5,
                        "color": "#d7cfc6",
                        "opacity": 0.1
                    },
                    {
                        "zoom": 6,
                        "color": "#d7cfc6",
                        "opacity": 0.2
                    },
                    {
                        "zoom": 7,
                        "color": "#d7cfc6",
                        "opacity": 0.3
                    },
                    {
                        "zoom": 8,
                        "color": "#d7cfc6",
                        "opacity": 0.4
                    },
                    {
                        "zoom": 9,
                        "color": "#d7cfc6",
                        "opacity": 0.6
                    },
                    {
                        "zoom": 10,
                        "color": "#d7cfc6",
                        "opacity": 0.8
                    },
                    {
                        "zoom": 11,
                        "color": "#d7cfc6",
                        "opacity": 1
                    },
                    {
                        "zoom": 12,
                        "color": "#d7cfc6",
                        "opacity": 1
                    },
                    {
                        "zoom": 13,
                        "color": "#d7cfc6",
                        "opacity": 1
                    },
                    {
                        "zoom": 14,
                        "color": "#dbd4cc",
                        "opacity": 1
                    },
                    {
                        "zoom": 15,
                        "color": "#e0d9d2",
                        "opacity": 1
                    },
                    {
                        "zoom": 16,
                        "color": "#e0d9d2",
                        "opacity": 0.7
                    },
                    {
                        "zoom": 17,
                        "color": "#e0d9d2",
                        "opacity": 0.7
                    },
                    {
                        "zoom": 18,
                        "color": "#e0d9d2",
                        "opacity": 0.7
                    },
                    {
                        "zoom": 19,
                        "color": "#e0d9d2",
                        "opacity": 0.7
                    },
                    {
                        "zoom": 20,
                        "color": "#e0d9d2",
                        "opacity": 0.7
                    },
                    {
                        "zoom": 21,
                        "color": "#e0d9d2",
                        "opacity": 0.7
                    }
                ]
            },
            {
                "tags": "cemetery",
                "elements": "geometry",
                "stylers": [
                    {
                        "zoom": 0,
                        "color": "#d7cfc6"
                    },
                    {
                        "zoom": 1,
                        "color": "#d7cfc6"
                    },
                    {
                        "zoom": 2,
                        "color": "#d7cfc6"
                    },
                    {
                        "zoom": 3,
                        "color": "#d7cfc6"
                    },
                    {
                        "zoom": 4,
                        "color": "#d7cfc6"
                    },
                    {
                        "zoom": 5,
                        "color": "#d7cfc6"
                    },
                    {
                        "zoom": 6,
                        "color": "#d7cfc6"
                    },
                    {
                        "zoom": 7,
                        "color": "#d7cfc6"
                    },
                    {
                        "zoom": 8,
                        "color": "#d7cfc6"
                    },
                    {
                        "zoom": 9,
                        "color": "#d7cfc6"
                    },
                    {
                        "zoom": 10,
                        "color": "#d7cfc6"
                    },
                    {
                        "zoom": 11,
                        "color": "#d7cfc6"
                    },
                    {
                        "zoom": 12,
                        "color": "#d7cfc6"
                    },
                    {
                        "zoom": 13,
                        "color": "#d7cfc6"
                    },
                    {
                        "zoom": 14,
                        "color": "#dbd4cc"
                    },
                    {
                        "zoom": 15,
                        "color": "#e0d9d2"
                    },
                    {
                        "zoom": 16,
                        "color": "#e0d9d2"
                    },
                    {
                        "zoom": 17,
                        "color": "#e0d9d2"
                    },
                    {
                        "zoom": 18,
                        "color": "#e0d9d2"
                    },
                    {
                        "zoom": 19,
                        "color": "#e0d9d2"
                    },
                    {
                        "zoom": 20,
                        "color": "#e0d9d2"
                    },
                    {
                        "zoom": 21,
                        "color": "#e0d9d2"
                    }
                ]
            },
            {
                "tags": "sports_ground",
                "elements": "geometry",
                "stylers": [
                    {
                        "zoom": 0,
                        "color": "#ccc2b7",
                        "opacity": 0
                    },
                    {
                        "zoom": 1,
                        "color": "#ccc2b7",
                        "opacity": 0
                    },
                    {
                        "zoom": 2,
                        "color": "#ccc2b7",
                        "opacity": 0
                    },
                    {
                        "zoom": 3,
                        "color": "#ccc2b7",
                        "opacity": 0
                    },
                    {
                        "zoom": 4,
                        "color": "#ccc2b7",
                        "opacity": 0
                    },
                    {
                        "zoom": 5,
                        "color": "#ccc2b7",
                        "opacity": 0
                    },
                    {
                        "zoom": 6,
                        "color": "#ccc2b7",
                        "opacity": 0
                    },
                    {
                        "zoom": 7,
                        "color": "#ccc2b7",
                        "opacity": 0
                    },
                    {
                        "zoom": 8,
                        "color": "#ccc2b7",
                        "opacity": 0
                    },
                    {
                        "zoom": 9,
                        "color": "#ccc2b7",
                        "opacity": 0
                    },
                    {
                        "zoom": 10,
                        "color": "#ccc2b7",
                        "opacity": 0
                    },
                    {
                        "zoom": 11,
                        "color": "#ccc2b7",
                        "opacity": 0
                    },
                    {
                        "zoom": 12,
                        "color": "#ccc2b7",
                        "opacity": 0
                    },
                    {
                        "zoom": 13,
                        "color": "#ccc2b7",
                        "opacity": 0
                    },
                    {
                        "zoom": 14,
                        "color": "#d0c7bd",
                        "opacity": 0
                    },
                    {
                        "zoom": 15,
                        "color": "#d5ccc3",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 16,
                        "color": "#d6cdc4",
                        "opacity": 1
                    },
                    {
                        "zoom": 17,
                        "color": "#d6cec5",
                        "opacity": 1
                    },
                    {
                        "zoom": 18,
                        "color": "#d7cec6",
                        "opacity": 1
                    },
                    {
                        "zoom": 19,
                        "color": "#d8cfc7",
                        "opacity": 1
                    },
                    {
                        "zoom": 20,
                        "color": "#d8d0c8",
                        "opacity": 1
                    },
                    {
                        "zoom": 21,
                        "color": "#d9d1c9",
                        "opacity": 1
                    }
                ]
            },
            {
                "tags": "terrain",
                "elements": "geometry",
                "stylers": [
                    {
                        "hue": "#eae6e1"
                    },
                    {
                        "zoom": 0,
                        "opacity": 0.3
                    },
                    {
                        "zoom": 1,
                        "opacity": 0.3
                    },
                    {
                        "zoom": 2,
                        "opacity": 0.3
                    },
                    {
                        "zoom": 3,
                        "opacity": 0.3
                    },
                    {
                        "zoom": 4,
                        "opacity": 0.3
                    },
                    {
                        "zoom": 5,
                        "opacity": 0.35
                    },
                    {
                        "zoom": 6,
                        "opacity": 0.4
                    },
                    {
                        "zoom": 7,
                        "opacity": 0.6
                    },
                    {
                        "zoom": 8,
                        "opacity": 0.8
                    },
                    {
                        "zoom": 9,
                        "opacity": 0.9
                    },
                    {
                        "zoom": 10,
                        "opacity": 1
                    },
                    {
                        "zoom": 11,
                        "opacity": 1
                    },
                    {
                        "zoom": 12,
                        "opacity": 1
                    },
                    {
                        "zoom": 13,
                        "opacity": 1
                    },
                    {
                        "zoom": 14,
                        "opacity": 1
                    },
                    {
                        "zoom": 15,
                        "opacity": 1
                    },
                    {
                        "zoom": 16,
                        "opacity": 1
                    },
                    {
                        "zoom": 17,
                        "opacity": 1
                    },
                    {
                        "zoom": 18,
                        "opacity": 1
                    },
                    {
                        "zoom": 19,
                        "opacity": 1
                    },
                    {
                        "zoom": 20,
                        "opacity": 1
                    },
                    {
                        "zoom": 21,
                        "opacity": 1
                    }
                ]
            },
            {
                "tags": "geographic_line",
                "elements": "geometry",
                "stylers": [
                    {
                        "color": "#94806b"
                    }
                ]
            },
            {
                "tags": "land",
                "elements": "geometry",
                "stylers": [
                    {
                        "zoom": 0,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 1,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 2,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 3,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 4,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 5,
                        "color": "#ece8e4"
                    },
                    {
                        "zoom": 6,
                        "color": "#eeebe7"
                    },
                    {
                        "zoom": 7,
                        "color": "#f0edea"
                    },
                    {
                        "zoom": 8,
                        "color": "#f2f0ed"
                    },
                    {
                        "zoom": 9,
                        "color": "#f2f0ed"
                    },
                    {
                        "zoom": 10,
                        "color": "#f2f0ed"
                    },
                    {
                        "zoom": 11,
                        "color": "#f2f0ed"
                    },
                    {
                        "zoom": 12,
                        "color": "#f2f0ed"
                    },
                    {
                        "zoom": 13,
                        "color": "#f2f0ed"
                    },
                    {
                        "zoom": 14,
                        "color": "#f4f2f0"
                    },
                    {
                        "zoom": 15,
                        "color": "#f6f5f3"
                    },
                    {
                        "zoom": 16,
                        "color": "#f6f5f3"
                    },
                    {
                        "zoom": 17,
                        "color": "#f7f6f4"
                    },
                    {
                        "zoom": 18,
                        "color": "#f7f6f4"
                    },
                    {
                        "zoom": 19,
                        "color": "#f8f7f5"
                    },
                    {
                        "zoom": 20,
                        "color": "#f8f7f5"
                    },
                    {
                        "zoom": 21,
                        "color": "#f9f8f6"
                    }
                ]
            },
            {
                "tags": "residential",
                "elements": "geometry",
                "stylers": [
                    {
                        "zoom": 0,
                        "color": "#eae6e1",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 1,
                        "color": "#eae6e1",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 2,
                        "color": "#eae6e1",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 3,
                        "color": "#eae6e1",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 4,
                        "color": "#eae6e1",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 5,
                        "color": "#eae6e1",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 6,
                        "color": "#eae6e1",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 7,
                        "color": "#eae6e1",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 8,
                        "color": "#eae6e1",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 9,
                        "color": "#eae6e1",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 10,
                        "color": "#eae6e1",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 11,
                        "color": "#eae6e1",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 12,
                        "color": "#eae6e1",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 13,
                        "color": "#eae6e1",
                        "opacity": 1
                    },
                    {
                        "zoom": 14,
                        "color": "#eeebe7",
                        "opacity": 1
                    },
                    {
                        "zoom": 15,
                        "color": "#f2f0ed",
                        "opacity": 1
                    },
                    {
                        "zoom": 16,
                        "color": "#f3f1ee",
                        "opacity": 1
                    },
                    {
                        "zoom": 17,
                        "color": "#f4f2ef",
                        "opacity": 1
                    },
                    {
                        "zoom": 18,
                        "color": "#f4f2f0",
                        "opacity": 1
                    },
                    {
                        "zoom": 19,
                        "color": "#f5f3f1",
                        "opacity": 1
                    },
                    {
                        "zoom": 20,
                        "color": "#f6f4f2",
                        "opacity": 1
                    },
                    {
                        "zoom": 21,
                        "color": "#f7f5f3",
                        "opacity": 1
                    }
                ]
            },
            {
                "tags": "locality",
                "elements": "geometry",
                "stylers": [
                    {
                        "zoom": 0,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 1,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 2,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 3,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 4,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 5,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 6,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 7,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 8,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 9,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 10,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 11,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 12,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 13,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 14,
                        "color": "#eeebe7"
                    },
                    {
                        "zoom": 15,
                        "color": "#f2f0ed"
                    },
                    {
                        "zoom": 16,
                        "color": "#f3f1ee"
                    },
                    {
                        "zoom": 17,
                        "color": "#f4f2ef"
                    },
                    {
                        "zoom": 18,
                        "color": "#f4f2f0"
                    },
                    {
                        "zoom": 19,
                        "color": "#f5f3f1"
                    },
                    {
                        "zoom": 20,
                        "color": "#f6f4f2"
                    },
                    {
                        "zoom": 21,
                        "color": "#f7f5f3"
                    }
                ]
            },
            {
                "tags": {
                    "any": "structure",
                    "none": [
                        "building",
                        "fence"
                    ]
                },
                "elements": "geometry",
                "stylers": [
                    {
                        "opacity": 0.9
                    },
                    {
                        "zoom": 0,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 1,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 2,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 3,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 4,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 5,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 6,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 7,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 8,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 9,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 10,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 11,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 12,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 13,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 14,
                        "color": "#eeebe7"
                    },
                    {
                        "zoom": 15,
                        "color": "#f2f0ed"
                    },
                    {
                        "zoom": 16,
                        "color": "#f3f1ee"
                    },
                    {
                        "zoom": 17,
                        "color": "#f4f2ef"
                    },
                    {
                        "zoom": 18,
                        "color": "#f4f2f0"
                    },
                    {
                        "zoom": 19,
                        "color": "#f5f3f1"
                    },
                    {
                        "zoom": 20,
                        "color": "#f6f4f2"
                    },
                    {
                        "zoom": 21,
                        "color": "#f7f5f3"
                    }
                ]
            },
            {
                "tags": "building",
                "elements": "geometry.fill",
                "stylers": [
                    {
                        "color": "#dfd9d3"
                    },
                    {
                        "zoom": 0,
                        "opacity": 0.7
                    },
                    {
                        "zoom": 1,
                        "opacity": 0.7
                    },
                    {
                        "zoom": 2,
                        "opacity": 0.7
                    },
                    {
                        "zoom": 3,
                        "opacity": 0.7
                    },
                    {
                        "zoom": 4,
                        "opacity": 0.7
                    },
                    {
                        "zoom": 5,
                        "opacity": 0.7
                    },
                    {
                        "zoom": 6,
                        "opacity": 0.7
                    },
                    {
                        "zoom": 7,
                        "opacity": 0.7
                    },
                    {
                        "zoom": 8,
                        "opacity": 0.7
                    },
                    {
                        "zoom": 9,
                        "opacity": 0.7
                    },
                    {
                        "zoom": 10,
                        "opacity": 0.7
                    },
                    {
                        "zoom": 11,
                        "opacity": 0.7
                    },
                    {
                        "zoom": 12,
                        "opacity": 0.7
                    },
                    {
                        "zoom": 13,
                        "opacity": 0.7
                    },
                    {
                        "zoom": 14,
                        "opacity": 0.7
                    },
                    {
                        "zoom": 15,
                        "opacity": 0.7
                    },
                    {
                        "zoom": 16,
                        "opacity": 0.9
                    },
                    {
                        "zoom": 17,
                        "opacity": 0.6
                    },
                    {
                        "zoom": 18,
                        "opacity": 0.6
                    },
                    {
                        "zoom": 19,
                        "opacity": 0.6
                    },
                    {
                        "zoom": 20,
                        "opacity": 0.6
                    },
                    {
                        "zoom": 21,
                        "opacity": 0.6
                    }
                ]
            },
            {
                "tags": "building",
                "elements": "geometry.outline",
                "stylers": [
                    {
                        "color": "#cdc5bc"
                    },
                    {
                        "zoom": 0,
                        "opacity": 0.5
                    },
                    {
                        "zoom": 1,
                        "opacity": 0.5
                    },
                    {
                        "zoom": 2,
                        "opacity": 0.5
                    },
                    {
                        "zoom": 3,
                        "opacity": 0.5
                    },
                    {
                        "zoom": 4,
                        "opacity": 0.5
                    },
                    {
                        "zoom": 5,
                        "opacity": 0.5
                    },
                    {
                        "zoom": 6,
                        "opacity": 0.5
                    },
                    {
                        "zoom": 7,
                        "opacity": 0.5
                    },
                    {
                        "zoom": 8,
                        "opacity": 0.5
                    },
                    {
                        "zoom": 9,
                        "opacity": 0.5
                    },
                    {
                        "zoom": 10,
                        "opacity": 0.5
                    },
                    {
                        "zoom": 11,
                        "opacity": 0.5
                    },
                    {
                        "zoom": 12,
                        "opacity": 0.5
                    },
                    {
                        "zoom": 13,
                        "opacity": 0.5
                    },
                    {
                        "zoom": 14,
                        "opacity": 0.5
                    },
                    {
                        "zoom": 15,
                        "opacity": 0.5
                    },
                    {
                        "zoom": 16,
                        "opacity": 0.5
                    },
                    {
                        "zoom": 17,
                        "opacity": 1
                    },
                    {
                        "zoom": 18,
                        "opacity": 1
                    },
                    {
                        "zoom": 19,
                        "opacity": 1
                    },
                    {
                        "zoom": 20,
                        "opacity": 1
                    },
                    {
                        "zoom": 21,
                        "opacity": 1
                    }
                ]
            },
            {
                "tags": {
                    "any": "urban_area",
                    "none": [
                        "residential",
                        "industrial",
                        "cemetery",
                        "park",
                        "medical",
                        "sports_ground",
                        "beach",
                        "construction_site"
                    ]
                },
                "elements": "geometry",
                "stylers": [
                    {
                        "zoom": 0,
                        "color": "#e1dbd6",
                        "opacity": 1
                    },
                    {
                        "zoom": 1,
                        "color": "#e1dbd6",
                        "opacity": 1
                    },
                    {
                        "zoom": 2,
                        "color": "#e1dbd6",
                        "opacity": 1
                    },
                    {
                        "zoom": 3,
                        "color": "#e1dbd6",
                        "opacity": 1
                    },
                    {
                        "zoom": 4,
                        "color": "#e1dbd6",
                        "opacity": 1
                    },
                    {
                        "zoom": 5,
                        "color": "#e1dbd6",
                        "opacity": 1
                    },
                    {
                        "zoom": 6,
                        "color": "#e1dbd6",
                        "opacity": 1
                    },
                    {
                        "zoom": 7,
                        "color": "#e1dbd6",
                        "opacity": 1
                    },
                    {
                        "zoom": 8,
                        "color": "#e1dbd6",
                        "opacity": 1
                    },
                    {
                        "zoom": 9,
                        "color": "#e1dbd6",
                        "opacity": 1
                    },
                    {
                        "zoom": 10,
                        "color": "#e1dbd6",
                        "opacity": 1
                    },
                    {
                        "zoom": 11,
                        "color": "#e1dbd6",
                        "opacity": 1
                    },
                    {
                        "zoom": 12,
                        "color": "#e1dbd6",
                        "opacity": 1
                    },
                    {
                        "zoom": 13,
                        "color": "#e1dbd6",
                        "opacity": 1
                    },
                    {
                        "zoom": 14,
                        "color": "#e6e1dd",
                        "opacity": 1
                    },
                    {
                        "zoom": 15,
                        "color": "#ebe8e5",
                        "opacity": 1
                    },
                    {
                        "zoom": 16,
                        "color": "#f0eeec",
                        "opacity": 0.67
                    },
                    {
                        "zoom": 17,
                        "color": "#f6f5f3",
                        "opacity": 0.33
                    },
                    {
                        "zoom": 18,
                        "color": "#f6f5f3",
                        "opacity": 0
                    },
                    {
                        "zoom": 19,
                        "color": "#f6f5f3",
                        "opacity": 0
                    },
                    {
                        "zoom": 20,
                        "color": "#f6f5f3",
                        "opacity": 0
                    },
                    {
                        "zoom": 21,
                        "color": "#f6f5f3",
                        "opacity": 0
                    }
                ]
            },
            {
                "tags": "poi",
                "elements": "label.icon",
                "stylers": [
                    {
                        "color": "#917c69"
                    },
                    {
                        "secondary-color": "#ffffff"
                    },
                    {
                        "tertiary-color": "#ffffff"
                    }
                ]
            },
            {
                "tags": "poi",
                "elements": "label.text.fill",
                "stylers": [
                    {
                        "color": "#594c40"
                    }
                ]
            },
            {
                "tags": "poi",
                "elements": "label.text.outline",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "opacity": 0.5
                    }
                ]
            },
            {
                "tags": "outdoor",
                "elements": "label.icon",
                "stylers": [
                    {
                        "color": "#917c69"
                    },
                    {
                        "secondary-color": "#ffffff"
                    },
                    {
                        "tertiary-color": "#ffffff"
                    }
                ]
            },
            {
                "tags": "outdoor",
                "elements": "label.text.fill",
                "stylers": [
                    {
                        "color": "#594c40"
                    }
                ]
            },
            {
                "tags": "outdoor",
                "elements": "label.text.outline",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "opacity": 0.5
                    }
                ]
            },
            {
                "tags": "park",
                "elements": "label.icon",
                "stylers": [
                    {
                        "color": "#917c69"
                    },
                    {
                        "secondary-color": "#ffffff"
                    },
                    {
                        "tertiary-color": "#ffffff"
                    }
                ]
            },
            {
                "tags": "park",
                "elements": "label.text.fill",
                "stylers": [
                    {
                        "color": "#594c40"
                    }
                ]
            },
            {
                "tags": "park",
                "elements": "label.text.outline",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "opacity": 0.5
                    }
                ]
            },
            {
                "tags": "cemetery",
                "elements": "label.icon",
                "stylers": [
                    {
                        "color": "#917c69"
                    },
                    {
                        "secondary-color": "#ffffff"
                    },
                    {
                        "tertiary-color": "#ffffff"
                    }
                ]
            },
            {
                "tags": "cemetery",
                "elements": "label.text.fill",
                "stylers": [
                    {
                        "color": "#594c40"
                    }
                ]
            },
            {
                "tags": "cemetery",
                "elements": "label.text.outline",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "opacity": 0.5
                    }
                ]
            },
            {
                "tags": "beach",
                "elements": "label.icon",
                "stylers": [
                    {
                        "color": "#917c69"
                    },
                    {
                        "secondary-color": "#ffffff"
                    },
                    {
                        "tertiary-color": "#ffffff"
                    }
                ]
            },
            {
                "tags": "beach",
                "elements": "label.text.fill",
                "stylers": [
                    {
                        "color": "#594c40"
                    }
                ]
            },
            {
                "tags": "beach",
                "elements": "label.text.outline",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "opacity": 0.5
                    }
                ]
            },
            {
                "tags": "medical",
                "elements": "label.icon",
                "stylers": [
                    {
                        "color": "#917c69"
                    },
                    {
                        "secondary-color": "#ffffff"
                    },
                    {
                        "tertiary-color": "#ffffff"
                    }
                ]
            },
            {
                "tags": "medical",
                "elements": "label.text.fill",
                "stylers": [
                    {
                        "color": "#594c40"
                    }
                ]
            },
            {
                "tags": "medical",
                "elements": "label.text.outline",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "opacity": 0.5
                    }
                ]
            },
            {
                "tags": "shopping",
                "elements": "label.icon",
                "stylers": [
                    {
                        "color": "#917c69"
                    },
                    {
                        "secondary-color": "#ffffff"
                    },
                    {
                        "tertiary-color": "#ffffff"
                    }
                ]
            },
            {
                "tags": "shopping",
                "elements": "label.text.fill",
                "stylers": [
                    {
                        "color": "#594c40"
                    }
                ]
            },
            {
                "tags": "shopping",
                "elements": "label.text.outline",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "opacity": 0.5
                    }
                ]
            },
            {
                "tags": "commercial_services",
                "elements": "label.icon",
                "stylers": [
                    {
                        "color": "#917c69"
                    },
                    {
                        "secondary-color": "#ffffff"
                    },
                    {
                        "tertiary-color": "#ffffff"
                    }
                ]
            },
            {
                "tags": "commercial_services",
                "elements": "label.text.fill",
                "stylers": [
                    {
                        "color": "#594c40"
                    }
                ]
            },
            {
                "tags": "commercial_services",
                "elements": "label.text.outline",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "opacity": 0.5
                    }
                ]
            },
            {
                "tags": "food_and_drink",
                "elements": "label.icon",
                "stylers": [
                    {
                        "color": "#917c69"
                    },
                    {
                        "secondary-color": "#ffffff"
                    },
                    {
                        "tertiary-color": "#ffffff"
                    }
                ]
            },
            {
                "tags": "food_and_drink",
                "elements": "label.text.fill",
                "stylers": [
                    {
                        "color": "#594c40"
                    }
                ]
            },
            {
                "tags": "food_and_drink",
                "elements": "label.text.outline",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "opacity": 0.5
                    }
                ]
            },
            {
                "tags": "road",
                "elements": "label.icon",
                "types": "point",
                "stylers": [
                    {
                        "color": "#917c69"
                    },
                    {
                        "secondary-color": "#ffffff"
                    },
                    {
                        "tertiary-color": "#ffffff"
                    }
                ]
            },
            {
                "tags": "road",
                "elements": "label.text.fill",
                "types": "point",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "tags": "entrance",
                "elements": "label.icon",
                "stylers": [
                    {
                        "color": "#917c69"
                    },
                    {
                        "secondary-color": "#ffffff"
                    },
                    {
                        "hue": "#917c69"
                    }
                ]
            },
            {
                "tags": "locality",
                "elements": "label.icon",
                "stylers": [
                    {
                        "color": "#917c69"
                    },
                    {
                        "secondary-color": "#ffffff"
                    }
                ]
            },
            {
                "tags": "country",
                "elements": "label.text.fill",
                "stylers": [
                    {
                        "opacity": 0.8
                    },
                    {
                        "color": "#857260"
                    }
                ]
            },
            {
                "tags": "country",
                "elements": "label.text.outline",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "opacity": 0.5
                    }
                ]
            },
            {
                "tags": "region",
                "elements": "label.text.fill",
                "stylers": [
                    {
                        "color": "#857260"
                    },
                    {
                        "opacity": 0.8
                    }
                ]
            },
            {
                "tags": "region",
                "elements": "label.text.outline",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "opacity": 0.5
                    }
                ]
            },
            {
                "tags": "district",
                "elements": "label.text.fill",
                "stylers": [
                    {
                        "color": "#857260"
                    },
                    {
                        "opacity": 0.8
                    }
                ]
            },
            {
                "tags": "district",
                "elements": "label.text.outline",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "opacity": 0.5
                    }
                ]
            },
            {
                "tags": {
                    "any": "admin",
                    "none": [
                        "country",
                        "region",
                        "locality",
                        "district",
                        "address"
                    ]
                },
                "elements": "label.text.fill",
                "stylers": [
                    {
                        "color": "#857260"
                    }
                ]
            },
            {
                "tags": {
                    "any": "admin",
                    "none": [
                        "country",
                        "region",
                        "locality",
                        "district",
                        "address"
                    ]
                },
                "elements": "label.text.outline",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "opacity": 0.5
                    }
                ]
            },
            {
                "tags": "locality",
                "elements": "label.text.fill",
                "stylers": [
                    {
                        "zoom": 0,
                        "color": "#594c40"
                    },
                    {
                        "zoom": 1,
                        "color": "#594c40"
                    },
                    {
                        "zoom": 2,
                        "color": "#594c40"
                    },
                    {
                        "zoom": 3,
                        "color": "#594c40"
                    },
                    {
                        "zoom": 4,
                        "color": "#594c40"
                    },
                    {
                        "zoom": 5,
                        "color": "#574a3e"
                    },
                    {
                        "zoom": 6,
                        "color": "#54483d"
                    },
                    {
                        "zoom": 7,
                        "color": "#52463b"
                    },
                    {
                        "zoom": 8,
                        "color": "#4f4339"
                    },
                    {
                        "zoom": 9,
                        "color": "#4d4138"
                    },
                    {
                        "zoom": 10,
                        "color": "#4a3f36"
                    },
                    {
                        "zoom": 11,
                        "color": "#4a3f36"
                    },
                    {
                        "zoom": 12,
                        "color": "#4a3f36"
                    },
                    {
                        "zoom": 13,
                        "color": "#4a3f36"
                    },
                    {
                        "zoom": 14,
                        "color": "#4a3f36"
                    },
                    {
                        "zoom": 15,
                        "color": "#4a3f36"
                    },
                    {
                        "zoom": 16,
                        "color": "#4a3f36"
                    },
                    {
                        "zoom": 17,
                        "color": "#4a3f36"
                    },
                    {
                        "zoom": 18,
                        "color": "#4a3f36"
                    },
                    {
                        "zoom": 19,
                        "color": "#4a3f36"
                    },
                    {
                        "zoom": 20,
                        "color": "#4a3f36"
                    },
                    {
                        "zoom": 21,
                        "color": "#4a3f36"
                    }
                ]
            },
            {
                "tags": "locality",
                "elements": "label.text.outline",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "opacity": 0.5
                    }
                ]
            },
            {
                "tags": "road",
                "elements": "label.text.fill",
                "types": "polyline",
                "stylers": [
                    {
                        "color": "#68594b"
                    }
                ]
            },
            {
                "tags": "road",
                "elements": "label.text.outline",
                "types": "polyline",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "opacity": 0.5
                    }
                ]
            },
            {
                "tags": "road",
                "elements": "geometry.fill.pattern",
                "types": "polyline",
                "stylers": [
                    {
                        "scale": 1
                    },
                    {
                        "color": "#9f8c7a"
                    }
                ]
            },
            {
                "tags": "road",
                "elements": "label.text.fill",
                "types": "point",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "tags": "structure",
                "elements": "label.text.fill",
                "stylers": [
                    {
                        "color": "#766656"
                    },
                    {
                        "opacity": 0.5
                    }
                ]
            },
            {
                "tags": "structure",
                "elements": "label.text.outline",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "opacity": 0.5
                    }
                ]
            },
            {
                "tags": "address",
                "elements": "label.text.fill",
                "stylers": [
                    {
                        "color": "#766656"
                    },
                    {
                        "zoom": 0,
                        "opacity": 0.9
                    },
                    {
                        "zoom": 1,
                        "opacity": 0.9
                    },
                    {
                        "zoom": 2,
                        "opacity": 0.9
                    },
                    {
                        "zoom": 3,
                        "opacity": 0.9
                    },
                    {
                        "zoom": 4,
                        "opacity": 0.9
                    },
                    {
                        "zoom": 5,
                        "opacity": 0.9
                    },
                    {
                        "zoom": 6,
                        "opacity": 0.9
                    },
                    {
                        "zoom": 7,
                        "opacity": 0.9
                    },
                    {
                        "zoom": 8,
                        "opacity": 0.9
                    },
                    {
                        "zoom": 9,
                        "opacity": 0.9
                    },
                    {
                        "zoom": 10,
                        "opacity": 0.9
                    },
                    {
                        "zoom": 11,
                        "opacity": 0.9
                    },
                    {
                        "zoom": 12,
                        "opacity": 0.9
                    },
                    {
                        "zoom": 13,
                        "opacity": 0.9
                    },
                    {
                        "zoom": 14,
                        "opacity": 0.9
                    },
                    {
                        "zoom": 15,
                        "opacity": 0.9
                    },
                    {
                        "zoom": 16,
                        "opacity": 0.9
                    },
                    {
                        "zoom": 17,
                        "opacity": 1
                    },
                    {
                        "zoom": 18,
                        "opacity": 1
                    },
                    {
                        "zoom": 19,
                        "opacity": 1
                    },
                    {
                        "zoom": 20,
                        "opacity": 1
                    },
                    {
                        "zoom": 21,
                        "opacity": 1
                    }
                ]
            },
            {
                "tags": "address",
                "elements": "label.text.outline",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "opacity": 0.5
                    }
                ]
            },
            {
                "tags": "landscape",
                "elements": "label.text.fill",
                "stylers": [
                    {
                        "zoom": 0,
                        "color": "#857260",
                        "opacity": 1
                    },
                    {
                        "zoom": 1,
                        "color": "#857260",
                        "opacity": 1
                    },
                    {
                        "zoom": 2,
                        "color": "#857260",
                        "opacity": 1
                    },
                    {
                        "zoom": 3,
                        "color": "#857260",
                        "opacity": 1
                    },
                    {
                        "zoom": 4,
                        "color": "#766656",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 5,
                        "color": "#766656",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 6,
                        "color": "#766656",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 7,
                        "color": "#766656",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 8,
                        "color": "#766656",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 9,
                        "color": "#766656",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 10,
                        "color": "#766656",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 11,
                        "color": "#766656",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 12,
                        "color": "#766656",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 13,
                        "color": "#766656",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 14,
                        "color": "#766656",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 15,
                        "color": "#766656",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 16,
                        "color": "#766656",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 17,
                        "color": "#766656",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 18,
                        "color": "#766656",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 19,
                        "color": "#766656",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 20,
                        "color": "#766656",
                        "opacity": 0.5
                    },
                    {
                        "zoom": 21,
                        "color": "#766656",
                        "opacity": 0.5
                    }
                ]
            },
            {
                "tags": "landscape",
                "elements": "label.text.outline",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "zoom": 0,
                        "opacity": 0.5
                    },
                    {
                        "zoom": 1,
                        "opacity": 0.5
                    },
                    {
                        "zoom": 2,
                        "opacity": 0.5
                    },
                    {
                        "zoom": 3,
                        "opacity": 0.5
                    },
                    {
                        "zoom": 4,
                        "opacity": 0
                    },
                    {
                        "zoom": 5,
                        "opacity": 0
                    },
                    {
                        "zoom": 6,
                        "opacity": 0
                    },
                    {
                        "zoom": 7,
                        "opacity": 0
                    },
                    {
                        "zoom": 8,
                        "opacity": 0
                    },
                    {
                        "zoom": 9,
                        "opacity": 0
                    },
                    {
                        "zoom": 10,
                        "opacity": 0
                    },
                    {
                        "zoom": 11,
                        "opacity": 0
                    },
                    {
                        "zoom": 12,
                        "opacity": 0
                    },
                    {
                        "zoom": 13,
                        "opacity": 0
                    },
                    {
                        "zoom": 14,
                        "opacity": 0
                    },
                    {
                        "zoom": 15,
                        "opacity": 0
                    },
                    {
                        "zoom": 16,
                        "opacity": 0
                    },
                    {
                        "zoom": 17,
                        "opacity": 0
                    },
                    {
                        "zoom": 18,
                        "opacity": 0
                    },
                    {
                        "zoom": 19,
                        "opacity": 0
                    },
                    {
                        "zoom": 20,
                        "opacity": 0
                    },
                    {
                        "zoom": 21,
                        "opacity": 0
                    }
                ]
            },
            {
                "tags": "water",
                "elements": "label.text.fill",
                "stylers": [
                    {
                        "color": "#766556"
                    },
                    {
                        "opacity": 0.8
                    }
                ]
            },
            {
                "tags": "water",
                "elements": "label.text.outline",
                "types": "polyline",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "opacity": 0.2
                    }
                ]
            },
            {
                "tags": {
                    "any": "road_1",
                    "none": "is_tunnel"
                },
                "elements": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "zoom": 0,
                        "scale": 0
                    },
                    {
                        "zoom": 1,
                        "scale": 0
                    },
                    {
                        "zoom": 2,
                        "scale": 0
                    },
                    {
                        "zoom": 3,
                        "scale": 0
                    },
                    {
                        "zoom": 4,
                        "scale": 0
                    },
                    {
                        "zoom": 5,
                        "scale": 0
                    },
                    {
                        "zoom": 6,
                        "scale": 2.97
                    },
                    {
                        "zoom": 7,
                        "scale": 3.19
                    },
                    {
                        "zoom": 8,
                        "scale": 3.53
                    },
                    {
                        "zoom": 9,
                        "scale": 4
                    },
                    {
                        "zoom": 10,
                        "scale": 3.61
                    },
                    {
                        "zoom": 11,
                        "scale": 3.06
                    },
                    {
                        "zoom": 12,
                        "scale": 2.64
                    },
                    {
                        "zoom": 13,
                        "scale": 2.27
                    },
                    {
                        "zoom": 14,
                        "scale": 2.03
                    },
                    {
                        "zoom": 15,
                        "scale": 1.9
                    },
                    {
                        "zoom": 16,
                        "scale": 1.86
                    },
                    {
                        "zoom": 17,
                        "scale": 1.48
                    },
                    {
                        "zoom": 18,
                        "scale": 1.21
                    },
                    {
                        "zoom": 19,
                        "scale": 1.04
                    },
                    {
                        "zoom": 20,
                        "scale": 0.94
                    },
                    {
                        "zoom": 21,
                        "scale": 0.9
                    }
                ]
            },
            {
                "tags": {
                    "any": "road_1"
                },
                "elements": "geometry.outline",
                "stylers": [
                    {
                        "zoom": 0,
                        "color": "#00000000",
                        "scale": 1.4
                    },
                    {
                        "zoom": 1,
                        "color": "#00000000",
                        "scale": 1.4
                    },
                    {
                        "zoom": 2,
                        "color": "#00000000",
                        "scale": 1.4
                    },
                    {
                        "zoom": 3,
                        "color": "#00000000",
                        "scale": 1.4
                    },
                    {
                        "zoom": 4,
                        "color": "#00000000",
                        "scale": 1.4
                    },
                    {
                        "zoom": 5,
                        "color": "#00000000",
                        "scale": 1.4
                    },
                    {
                        "zoom": 6,
                        "color": "#00000000",
                        "scale": 3.05
                    },
                    {
                        "zoom": 7,
                        "color": "#00000000",
                        "scale": 3.05
                    },
                    {
                        "zoom": 8,
                        "color": "#e6e1db",
                        "scale": 3.15
                    },
                    {
                        "zoom": 9,
                        "color": "#eae6e1",
                        "scale": 3.37
                    },
                    {
                        "zoom": 10,
                        "color": "#eae6e1",
                        "scale": 3.36
                    },
                    {
                        "zoom": 11,
                        "color": "#eae6e1",
                        "scale": 3.17
                    },
                    {
                        "zoom": 12,
                        "color": "#eae6e1",
                        "scale": 3
                    },
                    {
                        "zoom": 13,
                        "color": "#eae6e1",
                        "scale": 2.8
                    },
                    {
                        "zoom": 14,
                        "color": "#eeebe7",
                        "scale": 2.66
                    },
                    {
                        "zoom": 15,
                        "color": "#eeebe7",
                        "scale": 2.61
                    },
                    {
                        "zoom": 16,
                        "color": "#f0ede9",
                        "scale": 2.64
                    },
                    {
                        "zoom": 17,
                        "color": "#f1eeeb",
                        "scale": 2.14
                    },
                    {
                        "zoom": 18,
                        "color": "#f3f0ee",
                        "scale": 1.79
                    },
                    {
                        "zoom": 19,
                        "color": "#f5f2f0",
                        "scale": 1.55
                    },
                    {
                        "zoom": 20,
                        "color": "#f6f3f1",
                        "scale": 1.41
                    },
                    {
                        "zoom": 21,
                        "color": "#f7f5f3",
                        "scale": 1.35
                    }
                ]
            },
            {
                "tags": {
                    "any": "road_2",
                    "none": "is_tunnel"
                },
                "elements": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "zoom": 0,
                        "scale": 0
                    },
                    {
                        "zoom": 1,
                        "scale": 0
                    },
                    {
                        "zoom": 2,
                        "scale": 0
                    },
                    {
                        "zoom": 3,
                        "scale": 0
                    },
                    {
                        "zoom": 4,
                        "scale": 0
                    },
                    {
                        "zoom": 5,
                        "scale": 0
                    },
                    {
                        "zoom": 6,
                        "scale": 2.97
                    },
                    {
                        "zoom": 7,
                        "scale": 3.19
                    },
                    {
                        "zoom": 8,
                        "scale": 3.53
                    },
                    {
                        "zoom": 9,
                        "scale": 4
                    },
                    {
                        "zoom": 10,
                        "scale": 3.61
                    },
                    {
                        "zoom": 11,
                        "scale": 3.06
                    },
                    {
                        "zoom": 12,
                        "scale": 2.64
                    },
                    {
                        "zoom": 13,
                        "scale": 2.27
                    },
                    {
                        "zoom": 14,
                        "scale": 2.03
                    },
                    {
                        "zoom": 15,
                        "scale": 1.9
                    },
                    {
                        "zoom": 16,
                        "scale": 1.86
                    },
                    {
                        "zoom": 17,
                        "scale": 1.48
                    },
                    {
                        "zoom": 18,
                        "scale": 1.21
                    },
                    {
                        "zoom": 19,
                        "scale": 1.04
                    },
                    {
                        "zoom": 20,
                        "scale": 0.94
                    },
                    {
                        "zoom": 21,
                        "scale": 0.9
                    }
                ]
            },
            {
                "tags": {
                    "any": "road_2"
                },
                "elements": "geometry.outline",
                "stylers": [
                    {
                        "zoom": 0,
                        "color": "#00000000",
                        "scale": 1.4
                    },
                    {
                        "zoom": 1,
                        "color": "#00000000",
                        "scale": 1.4
                    },
                    {
                        "zoom": 2,
                        "color": "#00000000",
                        "scale": 1.4
                    },
                    {
                        "zoom": 3,
                        "color": "#00000000",
                        "scale": 1.4
                    },
                    {
                        "zoom": 4,
                        "color": "#00000000",
                        "scale": 1.4
                    },
                    {
                        "zoom": 5,
                        "color": "#00000000",
                        "scale": 1.4
                    },
                    {
                        "zoom": 6,
                        "color": "#00000000",
                        "scale": 3.05
                    },
                    {
                        "zoom": 7,
                        "color": "#00000000",
                        "scale": 3.05
                    },
                    {
                        "zoom": 8,
                        "color": "#e6e1db",
                        "scale": 3.15
                    },
                    {
                        "zoom": 9,
                        "color": "#eae6e1",
                        "scale": 3.37
                    },
                    {
                        "zoom": 10,
                        "color": "#eae6e1",
                        "scale": 3.36
                    },
                    {
                        "zoom": 11,
                        "color": "#eae6e1",
                        "scale": 3.17
                    },
                    {
                        "zoom": 12,
                        "color": "#eae6e1",
                        "scale": 3
                    },
                    {
                        "zoom": 13,
                        "color": "#eae6e1",
                        "scale": 2.8
                    },
                    {
                        "zoom": 14,
                        "color": "#eeebe7",
                        "scale": 2.66
                    },
                    {
                        "zoom": 15,
                        "color": "#eeebe7",
                        "scale": 2.61
                    },
                    {
                        "zoom": 16,
                        "color": "#f0ede9",
                        "scale": 2.64
                    },
                    {
                        "zoom": 17,
                        "color": "#f1eeeb",
                        "scale": 2.14
                    },
                    {
                        "zoom": 18,
                        "color": "#f3f0ee",
                        "scale": 1.79
                    },
                    {
                        "zoom": 19,
                        "color": "#f5f2f0",
                        "scale": 1.55
                    },
                    {
                        "zoom": 20,
                        "color": "#f6f3f1",
                        "scale": 1.41
                    },
                    {
                        "zoom": 21,
                        "color": "#f7f5f3",
                        "scale": 1.35
                    }
                ]
            },
            {
                "tags": {
                    "any": "road_3",
                    "none": "is_tunnel"
                },
                "elements": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "zoom": 0,
                        "scale": 0
                    },
                    {
                        "zoom": 1,
                        "scale": 0
                    },
                    {
                        "zoom": 2,
                        "scale": 0
                    },
                    {
                        "zoom": 3,
                        "scale": 0
                    },
                    {
                        "zoom": 4,
                        "scale": 0
                    },
                    {
                        "zoom": 5,
                        "scale": 0
                    },
                    {
                        "zoom": 6,
                        "scale": 0
                    },
                    {
                        "zoom": 7,
                        "scale": 0
                    },
                    {
                        "zoom": 8,
                        "scale": 0
                    },
                    {
                        "zoom": 9,
                        "scale": 2.51
                    },
                    {
                        "zoom": 10,
                        "scale": 2.62
                    },
                    {
                        "zoom": 11,
                        "scale": 1.68
                    },
                    {
                        "zoom": 12,
                        "scale": 1.67
                    },
                    {
                        "zoom": 13,
                        "scale": 1.38
                    },
                    {
                        "zoom": 14,
                        "scale": 1.19
                    },
                    {
                        "zoom": 15,
                        "scale": 1.08
                    },
                    {
                        "zoom": 16,
                        "scale": 1.04
                    },
                    {
                        "zoom": 17,
                        "scale": 0.91
                    },
                    {
                        "zoom": 18,
                        "scale": 0.84
                    },
                    {
                        "zoom": 19,
                        "scale": 0.82
                    },
                    {
                        "zoom": 20,
                        "scale": 0.84
                    },
                    {
                        "zoom": 21,
                        "scale": 0.9
                    }
                ]
            },
            {
                "tags": {
                    "any": "road_3"
                },
                "elements": "geometry.outline",
                "stylers": [
                    {
                        "zoom": 0,
                        "color": "#ffffff",
                        "scale": 1.6
                    },
                    {
                        "zoom": 1,
                        "color": "#ffffff",
                        "scale": 1.6
                    },
                    {
                        "zoom": 2,
                        "color": "#ffffff",
                        "scale": 1.6
                    },
                    {
                        "zoom": 3,
                        "color": "#ffffff",
                        "scale": 1.6
                    },
                    {
                        "zoom": 4,
                        "color": "#ffffff",
                        "scale": 1.6
                    },
                    {
                        "zoom": 5,
                        "color": "#ffffff",
                        "scale": 1.6
                    },
                    {
                        "zoom": 6,
                        "color": "#ffffff",
                        "scale": 1.6
                    },
                    {
                        "zoom": 7,
                        "color": "#ffffff",
                        "scale": 1.6
                    },
                    {
                        "zoom": 8,
                        "color": "#ffffff",
                        "scale": 1.29
                    },
                    {
                        "zoom": 9,
                        "color": "#eae6e1",
                        "scale": 4.21
                    },
                    {
                        "zoom": 10,
                        "color": "#eae6e1",
                        "scale": 2.74
                    },
                    {
                        "zoom": 11,
                        "color": "#eae6e1",
                        "scale": 2.04
                    },
                    {
                        "zoom": 12,
                        "color": "#eae6e1",
                        "scale": 2.13
                    },
                    {
                        "zoom": 13,
                        "color": "#eae6e1",
                        "scale": 1.88
                    },
                    {
                        "zoom": 14,
                        "color": "#eeebe7",
                        "scale": 1.7
                    },
                    {
                        "zoom": 15,
                        "color": "#eeebe7",
                        "scale": 1.59
                    },
                    {
                        "zoom": 16,
                        "color": "#f0ede9",
                        "scale": 1.55
                    },
                    {
                        "zoom": 17,
                        "color": "#f1eeeb",
                        "scale": 1.37
                    },
                    {
                        "zoom": 18,
                        "color": "#f3f0ee",
                        "scale": 1.27
                    },
                    {
                        "zoom": 19,
                        "color": "#f5f2f0",
                        "scale": 1.23
                    },
                    {
                        "zoom": 20,
                        "color": "#f6f3f1",
                        "scale": 1.26
                    },
                    {
                        "zoom": 21,
                        "color": "#f7f5f3",
                        "scale": 1.35
                    }
                ]
            },
            {
                "tags": {
                    "any": "road_4",
                    "none": "is_tunnel"
                },
                "elements": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "zoom": 0,
                        "scale": 0
                    },
                    {
                        "zoom": 1,
                        "scale": 0
                    },
                    {
                        "zoom": 2,
                        "scale": 0
                    },
                    {
                        "zoom": 3,
                        "scale": 0
                    },
                    {
                        "zoom": 4,
                        "scale": 0
                    },
                    {
                        "zoom": 5,
                        "scale": 0
                    },
                    {
                        "zoom": 6,
                        "scale": 0
                    },
                    {
                        "zoom": 7,
                        "scale": 0
                    },
                    {
                        "zoom": 8,
                        "scale": 0
                    },
                    {
                        "zoom": 9,
                        "scale": 0
                    },
                    {
                        "zoom": 10,
                        "scale": 1.69
                    },
                    {
                        "zoom": 11,
                        "scale": 1.26
                    },
                    {
                        "zoom": 12,
                        "scale": 1.41
                    },
                    {
                        "zoom": 13,
                        "scale": 1.19
                    },
                    {
                        "zoom": 14,
                        "scale": 1.04
                    },
                    {
                        "zoom": 15,
                        "scale": 0.97
                    },
                    {
                        "zoom": 16,
                        "scale": 1.15
                    },
                    {
                        "zoom": 17,
                        "scale": 0.99
                    },
                    {
                        "zoom": 18,
                        "scale": 0.89
                    },
                    {
                        "zoom": 19,
                        "scale": 0.85
                    },
                    {
                        "zoom": 20,
                        "scale": 0.85
                    },
                    {
                        "zoom": 21,
                        "scale": 0.9
                    }
                ]
            },
            {
                "tags": {
                    "any": "road_4"
                },
                "elements": "geometry.outline",
                "stylers": [
                    {
                        "zoom": 0,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 1,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 2,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 3,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 4,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 5,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 6,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 7,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 8,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 9,
                        "color": "#ffffff",
                        "scale": 1.12
                    },
                    {
                        "zoom": 10,
                        "color": "#eae6e1",
                        "scale": 1.9
                    },
                    {
                        "zoom": 11,
                        "color": "#eae6e1",
                        "scale": 1.62
                    },
                    {
                        "zoom": 12,
                        "color": "#eae6e1",
                        "scale": 1.83
                    },
                    {
                        "zoom": 13,
                        "color": "#eae6e1",
                        "scale": 1.64
                    },
                    {
                        "zoom": 14,
                        "color": "#eeebe7",
                        "scale": 1.51
                    },
                    {
                        "zoom": 15,
                        "color": "#eeebe7",
                        "scale": 1.44
                    },
                    {
                        "zoom": 16,
                        "color": "#f0ede9",
                        "scale": 1.69
                    },
                    {
                        "zoom": 17,
                        "color": "#f1eeeb",
                        "scale": 1.47
                    },
                    {
                        "zoom": 18,
                        "color": "#f3f0ee",
                        "scale": 1.34
                    },
                    {
                        "zoom": 19,
                        "color": "#f5f2f0",
                        "scale": 1.28
                    },
                    {
                        "zoom": 20,
                        "color": "#f6f3f1",
                        "scale": 1.28
                    },
                    {
                        "zoom": 21,
                        "color": "#f7f5f3",
                        "scale": 1.34
                    }
                ]
            },
            {
                "tags": {
                    "any": "road_5",
                    "none": "is_tunnel"
                },
                "elements": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "zoom": 0,
                        "scale": 0
                    },
                    {
                        "zoom": 1,
                        "scale": 0
                    },
                    {
                        "zoom": 2,
                        "scale": 0
                    },
                    {
                        "zoom": 3,
                        "scale": 0
                    },
                    {
                        "zoom": 4,
                        "scale": 0
                    },
                    {
                        "zoom": 5,
                        "scale": 0
                    },
                    {
                        "zoom": 6,
                        "scale": 0
                    },
                    {
                        "zoom": 7,
                        "scale": 0
                    },
                    {
                        "zoom": 8,
                        "scale": 0
                    },
                    {
                        "zoom": 9,
                        "scale": 0
                    },
                    {
                        "zoom": 10,
                        "scale": 0
                    },
                    {
                        "zoom": 11,
                        "scale": 0
                    },
                    {
                        "zoom": 12,
                        "scale": 1.25
                    },
                    {
                        "zoom": 13,
                        "scale": 0.95
                    },
                    {
                        "zoom": 14,
                        "scale": 0.81
                    },
                    {
                        "zoom": 15,
                        "scale": 0.95
                    },
                    {
                        "zoom": 16,
                        "scale": 1.1
                    },
                    {
                        "zoom": 17,
                        "scale": 0.93
                    },
                    {
                        "zoom": 18,
                        "scale": 0.85
                    },
                    {
                        "zoom": 19,
                        "scale": 0.82
                    },
                    {
                        "zoom": 20,
                        "scale": 0.84
                    },
                    {
                        "zoom": 21,
                        "scale": 0.9
                    }
                ]
            },
            {
                "tags": {
                    "any": "road_5"
                },
                "elements": "geometry.outline",
                "stylers": [
                    {
                        "zoom": 0,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 1,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 2,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 3,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 4,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 5,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 6,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 7,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 8,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 9,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 10,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 11,
                        "color": "#ffffff",
                        "scale": 0.62
                    },
                    {
                        "zoom": 12,
                        "color": "#eae6e1",
                        "scale": 1.61
                    },
                    {
                        "zoom": 13,
                        "color": "#eae6e1",
                        "scale": 1.36
                    },
                    {
                        "zoom": 14,
                        "color": "#eeebe7",
                        "scale": 1.22
                    },
                    {
                        "zoom": 15,
                        "color": "#eeebe7",
                        "scale": 1.41
                    },
                    {
                        "zoom": 16,
                        "color": "#f0ede9",
                        "scale": 1.63
                    },
                    {
                        "zoom": 17,
                        "color": "#f1eeeb",
                        "scale": 1.4
                    },
                    {
                        "zoom": 18,
                        "color": "#f3f0ee",
                        "scale": 1.27
                    },
                    {
                        "zoom": 19,
                        "color": "#f5f2f0",
                        "scale": 1.23
                    },
                    {
                        "zoom": 20,
                        "color": "#f6f3f1",
                        "scale": 1.25
                    },
                    {
                        "zoom": 21,
                        "color": "#f7f5f3",
                        "scale": 1.34
                    }
                ]
            },
            {
                "tags": {
                    "any": "road_6",
                    "none": "is_tunnel"
                },
                "elements": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "zoom": 0,
                        "scale": 0
                    },
                    {
                        "zoom": 1,
                        "scale": 0
                    },
                    {
                        "zoom": 2,
                        "scale": 0
                    },
                    {
                        "zoom": 3,
                        "scale": 0
                    },
                    {
                        "zoom": 4,
                        "scale": 0
                    },
                    {
                        "zoom": 5,
                        "scale": 0
                    },
                    {
                        "zoom": 6,
                        "scale": 0
                    },
                    {
                        "zoom": 7,
                        "scale": 0
                    },
                    {
                        "zoom": 8,
                        "scale": 0
                    },
                    {
                        "zoom": 9,
                        "scale": 0
                    },
                    {
                        "zoom": 10,
                        "scale": 0
                    },
                    {
                        "zoom": 11,
                        "scale": 0
                    },
                    {
                        "zoom": 12,
                        "scale": 0
                    },
                    {
                        "zoom": 13,
                        "scale": 2.25
                    },
                    {
                        "zoom": 14,
                        "scale": 1.27
                    },
                    {
                        "zoom": 15,
                        "scale": 1.25
                    },
                    {
                        "zoom": 16,
                        "scale": 1.31
                    },
                    {
                        "zoom": 17,
                        "scale": 1.04
                    },
                    {
                        "zoom": 18,
                        "scale": 0.9
                    },
                    {
                        "zoom": 19,
                        "scale": 0.85
                    },
                    {
                        "zoom": 20,
                        "scale": 0.85
                    },
                    {
                        "zoom": 21,
                        "scale": 0.9
                    }
                ]
            },
            {
                "tags": {
                    "any": "road_6"
                },
                "elements": "geometry.outline",
                "stylers": [
                    {
                        "zoom": 0,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 1,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 2,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 3,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 4,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 5,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 6,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 7,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 8,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 9,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 10,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 11,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 12,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 13,
                        "color": "#eae6e1",
                        "scale": 2.31
                    },
                    {
                        "zoom": 14,
                        "color": "#eeebe7",
                        "scale": 1.7
                    },
                    {
                        "zoom": 15,
                        "color": "#eeebe7",
                        "scale": 1.76
                    },
                    {
                        "zoom": 16,
                        "color": "#f0ede9",
                        "scale": 1.89
                    },
                    {
                        "zoom": 17,
                        "color": "#f1eeeb",
                        "scale": 1.55
                    },
                    {
                        "zoom": 18,
                        "color": "#f3f0ee",
                        "scale": 1.36
                    },
                    {
                        "zoom": 19,
                        "color": "#f5f2f0",
                        "scale": 1.27
                    },
                    {
                        "zoom": 20,
                        "color": "#f6f3f1",
                        "scale": 1.27
                    },
                    {
                        "zoom": 21,
                        "color": "#f7f5f3",
                        "scale": 1.34
                    }
                ]
            },
            {
                "tags": {
                    "any": "road_7",
                    "none": "is_tunnel"
                },
                "elements": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "zoom": 0,
                        "scale": 0
                    },
                    {
                        "zoom": 1,
                        "scale": 0
                    },
                    {
                        "zoom": 2,
                        "scale": 0
                    },
                    {
                        "zoom": 3,
                        "scale": 0
                    },
                    {
                        "zoom": 4,
                        "scale": 0
                    },
                    {
                        "zoom": 5,
                        "scale": 0
                    },
                    {
                        "zoom": 6,
                        "scale": 0
                    },
                    {
                        "zoom": 7,
                        "scale": 0
                    },
                    {
                        "zoom": 8,
                        "scale": 0
                    },
                    {
                        "zoom": 9,
                        "scale": 0
                    },
                    {
                        "zoom": 10,
                        "scale": 0
                    },
                    {
                        "zoom": 11,
                        "scale": 0
                    },
                    {
                        "zoom": 12,
                        "scale": 0
                    },
                    {
                        "zoom": 13,
                        "scale": 0
                    },
                    {
                        "zoom": 14,
                        "scale": 0.9
                    },
                    {
                        "zoom": 15,
                        "scale": 0.78
                    },
                    {
                        "zoom": 16,
                        "scale": 0.88
                    },
                    {
                        "zoom": 17,
                        "scale": 0.8
                    },
                    {
                        "zoom": 18,
                        "scale": 0.78
                    },
                    {
                        "zoom": 19,
                        "scale": 0.79
                    },
                    {
                        "zoom": 20,
                        "scale": 0.83
                    },
                    {
                        "zoom": 21,
                        "scale": 0.9
                    }
                ]
            },
            {
                "tags": {
                    "any": "road_7"
                },
                "elements": "geometry.outline",
                "stylers": [
                    {
                        "zoom": 0,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 1,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 2,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 3,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 4,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 5,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 6,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 7,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 8,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 9,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 10,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 11,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 12,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 13,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 14,
                        "color": "#eeebe7",
                        "scale": 1.31
                    },
                    {
                        "zoom": 15,
                        "color": "#eeebe7",
                        "scale": 1.19
                    },
                    {
                        "zoom": 16,
                        "color": "#f0ede9",
                        "scale": 1.31
                    },
                    {
                        "zoom": 17,
                        "color": "#f1eeeb",
                        "scale": 1.21
                    },
                    {
                        "zoom": 18,
                        "color": "#f3f0ee",
                        "scale": 1.17
                    },
                    {
                        "zoom": 19,
                        "color": "#f5f2f0",
                        "scale": 1.18
                    },
                    {
                        "zoom": 20,
                        "color": "#f6f3f1",
                        "scale": 1.23
                    },
                    {
                        "zoom": 21,
                        "color": "#f7f5f3",
                        "scale": 1.33
                    }
                ]
            },
            {
                "tags": {
                    "any": "road_minor",
                    "none": "is_tunnel"
                },
                "elements": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "zoom": 0,
                        "scale": 0
                    },
                    {
                        "zoom": 1,
                        "scale": 0
                    },
                    {
                        "zoom": 2,
                        "scale": 0
                    },
                    {
                        "zoom": 3,
                        "scale": 0
                    },
                    {
                        "zoom": 4,
                        "scale": 0
                    },
                    {
                        "zoom": 5,
                        "scale": 0
                    },
                    {
                        "zoom": 6,
                        "scale": 0
                    },
                    {
                        "zoom": 7,
                        "scale": 0
                    },
                    {
                        "zoom": 8,
                        "scale": 0
                    },
                    {
                        "zoom": 9,
                        "scale": 0
                    },
                    {
                        "zoom": 10,
                        "scale": 0
                    },
                    {
                        "zoom": 11,
                        "scale": 0
                    },
                    {
                        "zoom": 12,
                        "scale": 0
                    },
                    {
                        "zoom": 13,
                        "scale": 0
                    },
                    {
                        "zoom": 14,
                        "scale": 0
                    },
                    {
                        "zoom": 15,
                        "scale": 0
                    },
                    {
                        "zoom": 16,
                        "scale": 0.9
                    },
                    {
                        "zoom": 17,
                        "scale": 0.9
                    },
                    {
                        "zoom": 18,
                        "scale": 0.9
                    },
                    {
                        "zoom": 19,
                        "scale": 0.9
                    },
                    {
                        "zoom": 20,
                        "scale": 0.9
                    },
                    {
                        "zoom": 21,
                        "scale": 0.9
                    }
                ]
            },
            {
                "tags": {
                    "any": "road_minor"
                },
                "elements": "geometry.outline",
                "stylers": [
                    {
                        "zoom": 0,
                        "color": "#ffffff",
                        "scale": 0.4
                    },
                    {
                        "zoom": 1,
                        "color": "#ffffff",
                        "scale": 0.4
                    },
                    {
                        "zoom": 2,
                        "color": "#ffffff",
                        "scale": 0.4
                    },
                    {
                        "zoom": 3,
                        "color": "#ffffff",
                        "scale": 0.4
                    },
                    {
                        "zoom": 4,
                        "color": "#ffffff",
                        "scale": 0.4
                    },
                    {
                        "zoom": 5,
                        "color": "#ffffff",
                        "scale": 0.4
                    },
                    {
                        "zoom": 6,
                        "color": "#ffffff",
                        "scale": 0.4
                    },
                    {
                        "zoom": 7,
                        "color": "#ffffff",
                        "scale": 0.4
                    },
                    {
                        "zoom": 8,
                        "color": "#ffffff",
                        "scale": 0.4
                    },
                    {
                        "zoom": 9,
                        "color": "#ffffff",
                        "scale": 0.4
                    },
                    {
                        "zoom": 10,
                        "color": "#ffffff",
                        "scale": 0.4
                    },
                    {
                        "zoom": 11,
                        "color": "#ffffff",
                        "scale": 0.4
                    },
                    {
                        "zoom": 12,
                        "color": "#ffffff",
                        "scale": 0.4
                    },
                    {
                        "zoom": 13,
                        "color": "#ffffff",
                        "scale": 0.4
                    },
                    {
                        "zoom": 14,
                        "color": "#eeebe7",
                        "scale": 0.4
                    },
                    {
                        "zoom": 15,
                        "color": "#eeebe7",
                        "scale": 0.4
                    },
                    {
                        "zoom": 16,
                        "color": "#f0ede9",
                        "scale": 1.4
                    },
                    {
                        "zoom": 17,
                        "color": "#f1eeeb",
                        "scale": 1.27
                    },
                    {
                        "zoom": 18,
                        "color": "#f3f0ee",
                        "scale": 1.27
                    },
                    {
                        "zoom": 19,
                        "color": "#f5f2f0",
                        "scale": 1.29
                    },
                    {
                        "zoom": 20,
                        "color": "#f6f3f1",
                        "scale": 1.31
                    },
                    {
                        "zoom": 21,
                        "color": "#f7f5f3",
                        "scale": 1.32
                    }
                ]
            },
            {
                "tags": {
                    "any": "road_unclassified",
                    "none": "is_tunnel"
                },
                "elements": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "zoom": 0,
                        "scale": 0
                    },
                    {
                        "zoom": 1,
                        "scale": 0
                    },
                    {
                        "zoom": 2,
                        "scale": 0
                    },
                    {
                        "zoom": 3,
                        "scale": 0
                    },
                    {
                        "zoom": 4,
                        "scale": 0
                    },
                    {
                        "zoom": 5,
                        "scale": 0
                    },
                    {
                        "zoom": 6,
                        "scale": 0
                    },
                    {
                        "zoom": 7,
                        "scale": 0
                    },
                    {
                        "zoom": 8,
                        "scale": 0
                    },
                    {
                        "zoom": 9,
                        "scale": 0
                    },
                    {
                        "zoom": 10,
                        "scale": 0
                    },
                    {
                        "zoom": 11,
                        "scale": 0
                    },
                    {
                        "zoom": 12,
                        "scale": 0
                    },
                    {
                        "zoom": 13,
                        "scale": 0
                    },
                    {
                        "zoom": 14,
                        "scale": 0
                    },
                    {
                        "zoom": 15,
                        "scale": 0
                    },
                    {
                        "zoom": 16,
                        "scale": 0.9
                    },
                    {
                        "zoom": 17,
                        "scale": 0.9
                    },
                    {
                        "zoom": 18,
                        "scale": 0.9
                    },
                    {
                        "zoom": 19,
                        "scale": 0.9
                    },
                    {
                        "zoom": 20,
                        "scale": 0.9
                    },
                    {
                        "zoom": 21,
                        "scale": 0.9
                    }
                ]
            },
            {
                "tags": {
                    "any": "road_unclassified"
                },
                "elements": "geometry.outline",
                "stylers": [
                    {
                        "zoom": 0,
                        "color": "#ffffff",
                        "scale": 0.4
                    },
                    {
                        "zoom": 1,
                        "color": "#ffffff",
                        "scale": 0.4
                    },
                    {
                        "zoom": 2,
                        "color": "#ffffff",
                        "scale": 0.4
                    },
                    {
                        "zoom": 3,
                        "color": "#ffffff",
                        "scale": 0.4
                    },
                    {
                        "zoom": 4,
                        "color": "#ffffff",
                        "scale": 0.4
                    },
                    {
                        "zoom": 5,
                        "color": "#ffffff",
                        "scale": 0.4
                    },
                    {
                        "zoom": 6,
                        "color": "#ffffff",
                        "scale": 0.4
                    },
                    {
                        "zoom": 7,
                        "color": "#ffffff",
                        "scale": 0.4
                    },
                    {
                        "zoom": 8,
                        "color": "#ffffff",
                        "scale": 0.4
                    },
                    {
                        "zoom": 9,
                        "color": "#ffffff",
                        "scale": 0.4
                    },
                    {
                        "zoom": 10,
                        "color": "#ffffff",
                        "scale": 0.4
                    },
                    {
                        "zoom": 11,
                        "color": "#ffffff",
                        "scale": 0.4
                    },
                    {
                        "zoom": 12,
                        "color": "#ffffff",
                        "scale": 0.4
                    },
                    {
                        "zoom": 13,
                        "color": "#ffffff",
                        "scale": 0.4
                    },
                    {
                        "zoom": 14,
                        "color": "#eeebe7",
                        "scale": 0.4
                    },
                    {
                        "zoom": 15,
                        "color": "#eeebe7",
                        "scale": 0.4
                    },
                    {
                        "zoom": 16,
                        "color": "#f0ede9",
                        "scale": 1.4
                    },
                    {
                        "zoom": 17,
                        "color": "#f1eeeb",
                        "scale": 1.27
                    },
                    {
                        "zoom": 18,
                        "color": "#f3f0ee",
                        "scale": 1.27
                    },
                    {
                        "zoom": 19,
                        "color": "#f5f2f0",
                        "scale": 1.29
                    },
                    {
                        "zoom": 20,
                        "color": "#f6f3f1",
                        "scale": 1.31
                    },
                    {
                        "zoom": 21,
                        "color": "#f7f5f3",
                        "scale": 1.32
                    }
                ]
            },
            {
                "tags": {
                    "all": "is_tunnel",
                    "none": "path"
                },
                "elements": "geometry.fill",
                "stylers": [
                    {
                        "zoom": 0,
                        "color": "#e6e1db"
                    },
                    {
                        "zoom": 1,
                        "color": "#e6e1db"
                    },
                    {
                        "zoom": 2,
                        "color": "#e6e1db"
                    },
                    {
                        "zoom": 3,
                        "color": "#e6e1db"
                    },
                    {
                        "zoom": 4,
                        "color": "#e6e1db"
                    },
                    {
                        "zoom": 5,
                        "color": "#e6e1db"
                    },
                    {
                        "zoom": 6,
                        "color": "#e6e1db"
                    },
                    {
                        "zoom": 7,
                        "color": "#e6e1db"
                    },
                    {
                        "zoom": 8,
                        "color": "#e6e1db"
                    },
                    {
                        "zoom": 9,
                        "color": "#e6e1db"
                    },
                    {
                        "zoom": 10,
                        "color": "#e6e1db"
                    },
                    {
                        "zoom": 11,
                        "color": "#e6e1db"
                    },
                    {
                        "zoom": 12,
                        "color": "#e6e1db"
                    },
                    {
                        "zoom": 13,
                        "color": "#e6e1db"
                    },
                    {
                        "zoom": 14,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 15,
                        "color": "#eeebe7"
                    },
                    {
                        "zoom": 16,
                        "color": "#efece8"
                    },
                    {
                        "zoom": 17,
                        "color": "#efede9"
                    },
                    {
                        "zoom": 18,
                        "color": "#f0edea"
                    },
                    {
                        "zoom": 19,
                        "color": "#f1eeeb"
                    },
                    {
                        "zoom": 20,
                        "color": "#f1efec"
                    },
                    {
                        "zoom": 21,
                        "color": "#f2f0ed"
                    }
                ]
            },
            {
                "tags": {
                    "all": "path",
                    "none": "is_tunnel"
                },
                "elements": "geometry.fill",
                "stylers": [
                    {
                        "color": "#b4a597"
                    }
                ]
            },
            {
                "tags": {
                    "all": "path",
                    "none": "is_tunnel"
                },
                "elements": "geometry.outline",
                "stylers": [
                    {
                        "opacity": 0.7
                    },
                    {
                        "zoom": 0,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 1,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 2,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 3,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 4,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 5,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 6,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 7,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 8,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 9,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 10,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 11,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 12,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 13,
                        "color": "#eae6e1"
                    },
                    {
                        "zoom": 14,
                        "color": "#eeebe7"
                    },
                    {
                        "zoom": 15,
                        "color": "#f2f0ed"
                    },
                    {
                        "zoom": 16,
                        "color": "#f3f1ee"
                    },
                    {
                        "zoom": 17,
                        "color": "#f4f2ef"
                    },
                    {
                        "zoom": 18,
                        "color": "#f4f2f0"
                    },
                    {
                        "zoom": 19,
                        "color": "#f5f3f1"
                    },
                    {
                        "zoom": 20,
                        "color": "#f6f4f2"
                    },
                    {
                        "zoom": 21,
                        "color": "#f7f5f3"
                    }
                ]
            },
            {
                "tags": "road_construction",
                "elements": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "tags": "road_construction",
                "elements": "geometry.outline",
                "stylers": [
                    {
                        "zoom": 0,
                        "color": "#c9bfb5"
                    },
                    {
                        "zoom": 1,
                        "color": "#c9bfb5"
                    },
                    {
                        "zoom": 2,
                        "color": "#c9bfb5"
                    },
                    {
                        "zoom": 3,
                        "color": "#c9bfb5"
                    },
                    {
                        "zoom": 4,
                        "color": "#c9bfb5"
                    },
                    {
                        "zoom": 5,
                        "color": "#c9bfb5"
                    },
                    {
                        "zoom": 6,
                        "color": "#c9bfb5"
                    },
                    {
                        "zoom": 7,
                        "color": "#c9bfb5"
                    },
                    {
                        "zoom": 8,
                        "color": "#c9bfb5"
                    },
                    {
                        "zoom": 9,
                        "color": "#c9bfb5"
                    },
                    {
                        "zoom": 10,
                        "color": "#c9bfb5"
                    },
                    {
                        "zoom": 11,
                        "color": "#c9bfb5"
                    },
                    {
                        "zoom": 12,
                        "color": "#c9bfb5"
                    },
                    {
                        "zoom": 13,
                        "color": "#c9bfb5"
                    },
                    {
                        "zoom": 14,
                        "color": "#b4a597"
                    },
                    {
                        "zoom": 15,
                        "color": "#c9bfb5"
                    },
                    {
                        "zoom": 16,
                        "color": "#cdc3ba"
                    },
                    {
                        "zoom": 17,
                        "color": "#d0c8bf"
                    },
                    {
                        "zoom": 18,
                        "color": "#d4ccc4"
                    },
                    {
                        "zoom": 19,
                        "color": "#d8d0c9"
                    },
                    {
                        "zoom": 20,
                        "color": "#dbd5ce"
                    },
                    {
                        "zoom": 21,
                        "color": "#dfd9d3"
                    }
                ]
            },
            {
                "tags": {
                    "any": "ferry"
                },
                "stylers": [
                    {
                        "color": "#a99889"
                    }
                ]
            },
            {
                "tags": "transit_location",
                "elements": "label.icon",
                "stylers": [
                    {
                        "hue": "#917c69"
                    },
                    {
                        "saturation": -0.84
                    }
                ]
            },
            {
                "tags": "transit_location",
                "elements": "label.text.fill",
                "stylers": [
                    {
                        "color": "#b8987a"
                    }
                ]
            },
            {
                "tags": "transit_location",
                "elements": "label.text.outline",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "tags": "transit_schema",
                "elements": "geometry.fill",
                "stylers": [
                    {
                        "color": "#b8987a"
                    },
                    {
                        "scale": 0.7
                    },
                    {
                        "zoom": 0,
                        "opacity": 0.6
                    },
                    {
                        "zoom": 1,
                        "opacity": 0.6
                    },
                    {
                        "zoom": 2,
                        "opacity": 0.6
                    },
                    {
                        "zoom": 3,
                        "opacity": 0.6
                    },
                    {
                        "zoom": 4,
                        "opacity": 0.6
                    },
                    {
                        "zoom": 5,
                        "opacity": 0.6
                    },
                    {
                        "zoom": 6,
                        "opacity": 0.6
                    },
                    {
                        "zoom": 7,
                        "opacity": 0.6
                    },
                    {
                        "zoom": 8,
                        "opacity": 0.6
                    },
                    {
                        "zoom": 9,
                        "opacity": 0.6
                    },
                    {
                        "zoom": 10,
                        "opacity": 0.6
                    },
                    {
                        "zoom": 11,
                        "opacity": 0.6
                    },
                    {
                        "zoom": 12,
                        "opacity": 0.6
                    },
                    {
                        "zoom": 13,
                        "opacity": 0.6
                    },
                    {
                        "zoom": 14,
                        "opacity": 0.6
                    },
                    {
                        "zoom": 15,
                        "opacity": 0.5
                    },
                    {
                        "zoom": 16,
                        "opacity": 0.4
                    },
                    {
                        "zoom": 17,
                        "opacity": 0.4
                    },
                    {
                        "zoom": 18,
                        "opacity": 0.4
                    },
                    {
                        "zoom": 19,
                        "opacity": 0.4
                    },
                    {
                        "zoom": 20,
                        "opacity": 0.4
                    },
                    {
                        "zoom": 21,
                        "opacity": 0.4
                    }
                ]
            },
            {
                "tags": "transit_schema",
                "elements": "geometry.outline",
                "stylers": [
                    {
                        "opacity": 0
                    }
                ]
            },
            {
                "tags": "transit_line",
                "elements": "geometry.fill.pattern",
                "stylers": [
                    {
                        "color": "#c2b2a3"
                    },
                    {
                        "zoom": 0,
                        "opacity": 0
                    },
                    {
                        "zoom": 1,
                        "opacity": 0
                    },
                    {
                        "zoom": 2,
                        "opacity": 0
                    },
                    {
                        "zoom": 3,
                        "opacity": 0
                    },
                    {
                        "zoom": 4,
                        "opacity": 0
                    },
                    {
                        "zoom": 5,
                        "opacity": 0
                    },
                    {
                        "zoom": 6,
                        "opacity": 0
                    },
                    {
                        "zoom": 7,
                        "opacity": 0
                    },
                    {
                        "zoom": 8,
                        "opacity": 0
                    },
                    {
                        "zoom": 9,
                        "opacity": 0
                    },
                    {
                        "zoom": 10,
                        "opacity": 0
                    },
                    {
                        "zoom": 11,
                        "opacity": 0
                    },
                    {
                        "zoom": 12,
                        "opacity": 0
                    },
                    {
                        "zoom": 13,
                        "opacity": 1
                    },
                    {
                        "zoom": 14,
                        "opacity": 1
                    },
                    {
                        "zoom": 15,
                        "opacity": 1
                    },
                    {
                        "zoom": 16,
                        "opacity": 1
                    },
                    {
                        "zoom": 17,
                        "opacity": 1
                    },
                    {
                        "zoom": 18,
                        "opacity": 1
                    },
                    {
                        "zoom": 19,
                        "opacity": 1
                    },
                    {
                        "zoom": 20,
                        "opacity": 1
                    },
                    {
                        "zoom": 21,
                        "opacity": 1
                    }
                ]
            },
            {
                "tags": "transit_line",
                "elements": "geometry.fill",
                "stylers": [
                    {
                        "color": "#c2b2a3"
                    },
                    {
                        "scale": 0.4
                    },
                    {
                        "zoom": 0,
                        "opacity": 0
                    },
                    {
                        "zoom": 1,
                        "opacity": 0
                    },
                    {
                        "zoom": 2,
                        "opacity": 0
                    },
                    {
                        "zoom": 3,
                        "opacity": 0
                    },
                    {
                        "zoom": 4,
                        "opacity": 0
                    },
                    {
                        "zoom": 5,
                        "opacity": 0
                    },
                    {
                        "zoom": 6,
                        "opacity": 0
                    },
                    {
                        "zoom": 7,
                        "opacity": 0
                    },
                    {
                        "zoom": 8,
                        "opacity": 0
                    },
                    {
                        "zoom": 9,
                        "opacity": 0
                    },
                    {
                        "zoom": 10,
                        "opacity": 0
                    },
                    {
                        "zoom": 11,
                        "opacity": 0
                    },
                    {
                        "zoom": 12,
                        "opacity": 0
                    },
                    {
                        "zoom": 13,
                        "opacity": 1
                    },
                    {
                        "zoom": 14,
                        "opacity": 1
                    },
                    {
                        "zoom": 15,
                        "opacity": 1
                    },
                    {
                        "zoom": 16,
                        "opacity": 1
                    },
                    {
                        "zoom": 17,
                        "opacity": 1
                    },
                    {
                        "zoom": 18,
                        "opacity": 1
                    },
                    {
                        "zoom": 19,
                        "opacity": 1
                    },
                    {
                        "zoom": 20,
                        "opacity": 1
                    },
                    {
                        "zoom": 21,
                        "opacity": 1
                    }
                ]
            },
            {
                "tags": "water",
                "elements": "geometry",
                "stylers": [
                    {
                        "zoom": 0,
                        "color": "#bfb2a6"
                    },
                    {
                        "zoom": 1,
                        "color": "#bfb2a6"
                    },
                    {
                        "zoom": 2,
                        "color": "#bfb2a6"
                    },
                    {
                        "zoom": 3,
                        "color": "#bfb2a6"
                    },
                    {
                        "zoom": 4,
                        "color": "#bfb2a6"
                    },
                    {
                        "zoom": 5,
                        "color": "#bfb2a6"
                    },
                    {
                        "zoom": 6,
                        "color": "#bfb2a6"
                    },
                    {
                        "zoom": 7,
                        "color": "#bfb2a6"
                    },
                    {
                        "zoom": 8,
                        "color": "#c0b4a8"
                    },
                    {
                        "zoom": 9,
                        "color": "#c2b5aa"
                    },
                    {
                        "zoom": 10,
                        "color": "#c3b7ac"
                    },
                    {
                        "zoom": 11,
                        "color": "#c4b8ad"
                    },
                    {
                        "zoom": 12,
                        "color": "#c4b9ae"
                    },
                    {
                        "zoom": 13,
                        "color": "#c5baaf"
                    },
                    {
                        "zoom": 14,
                        "color": "#c6bbb0"
                    },
                    {
                        "zoom": 15,
                        "color": "#c7bcb2"
                    },
                    {
                        "zoom": 16,
                        "color": "#c8beb3"
                    },
                    {
                        "zoom": 17,
                        "color": "#c9bfb5"
                    },
                    {
                        "zoom": 18,
                        "color": "#cbc0b6"
                    },
                    {
                        "zoom": 19,
                        "color": "#ccc1b8"
                    },
                    {
                        "zoom": 20,
                        "color": "#cdc3b9"
                    },
                    {
                        "zoom": 21,
                        "color": "#cec4bb"
                    }
                ]
            },
            {
                "tags": "water",
                "elements": "geometry",
                "types": "polyline",
                "stylers": [
                    {
                        "zoom": 0,
                        "opacity": 0.4
                    },
                    {
                        "zoom": 1,
                        "opacity": 0.4
                    },
                    {
                        "zoom": 2,
                        "opacity": 0.4
                    },
                    {
                        "zoom": 3,
                        "opacity": 0.4
                    },
                    {
                        "zoom": 4,
                        "opacity": 0.6
                    },
                    {
                        "zoom": 5,
                        "opacity": 0.8
                    },
                    {
                        "zoom": 6,
                        "opacity": 1
                    },
                    {
                        "zoom": 7,
                        "opacity": 1
                    },
                    {
                        "zoom": 8,
                        "opacity": 1
                    },
                    {
                        "zoom": 9,
                        "opacity": 1
                    },
                    {
                        "zoom": 10,
                        "opacity": 1
                    },
                    {
                        "zoom": 11,
                        "opacity": 1
                    },
                    {
                        "zoom": 12,
                        "opacity": 1
                    },
                    {
                        "zoom": 13,
                        "opacity": 1
                    },
                    {
                        "zoom": 14,
                        "opacity": 1
                    },
                    {
                        "zoom": 15,
                        "opacity": 1
                    },
                    {
                        "zoom": 16,
                        "opacity": 1
                    },
                    {
                        "zoom": 17,
                        "opacity": 1
                    },
                    {
                        "zoom": 18,
                        "opacity": 1
                    },
                    {
                        "zoom": 19,
                        "opacity": 1
                    },
                    {
                        "zoom": 20,
                        "opacity": 1
                    },
                    {
                        "zoom": 21,
                        "opacity": 1
                    }
                ]
            },
            {
                "tags": "bathymetry",
                "elements": "geometry",
                "stylers": [
                    {
                        "hue": "#bfb2a6"
                    }
                ]
            },
            {
                "tags": {
                    "any": [
                        "industrial",
                        "construction_site"
                    ]
                },
                "elements": "geometry",
                "stylers": [
                    {
                        "zoom": 0,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 1,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 2,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 3,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 4,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 5,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 6,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 7,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 8,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 9,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 10,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 11,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 12,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 13,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 14,
                        "color": "#e9e5e2"
                    },
                    {
                        "zoom": 15,
                        "color": "#eeeae8"
                    },
                    {
                        "zoom": 16,
                        "color": "#efebe9"
                    },
                    {
                        "zoom": 17,
                        "color": "#efecea"
                    },
                    {
                        "zoom": 18,
                        "color": "#f0ecea"
                    },
                    {
                        "zoom": 19,
                        "color": "#f1edeb"
                    },
                    {
                        "zoom": 20,
                        "color": "#f1eeec"
                    },
                    {
                        "zoom": 21,
                        "color": "#f2efed"
                    }
                ]
            },
            {
                "tags": {
                    "any": "transit",
                    "none": [
                        "transit_location",
                        "transit_line",
                        "transit_schema",
                        "is_unclassified_transit"
                    ]
                },
                "elements": "geometry",
                "stylers": [
                    {
                        "zoom": 0,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 1,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 2,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 3,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 4,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 5,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 6,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 7,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 8,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 9,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 10,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 11,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 12,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 13,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 14,
                        "color": "#e9e5e2"
                    },
                    {
                        "zoom": 15,
                        "color": "#eeeae8"
                    },
                    {
                        "zoom": 16,
                        "color": "#efebe9"
                    },
                    {
                        "zoom": 17,
                        "color": "#efecea"
                    },
                    {
                        "zoom": 18,
                        "color": "#f0ecea"
                    },
                    {
                        "zoom": 19,
                        "color": "#f1edeb"
                    },
                    {
                        "zoom": 20,
                        "color": "#f1eeec"
                    },
                    {
                        "zoom": 21,
                        "color": "#f2efed"
                    }
                ]
            },
            {
                "tags": "fence",
                "elements": "geometry.fill",
                "stylers": [
                    {
                        "color": "#dcd6d0"
                    },
                    {
                        "zoom": 0,
                        "opacity": 0.75
                    },
                    {
                        "zoom": 1,
                        "opacity": 0.75
                    },
                    {
                        "zoom": 2,
                        "opacity": 0.75
                    },
                    {
                        "zoom": 3,
                        "opacity": 0.75
                    },
                    {
                        "zoom": 4,
                        "opacity": 0.75
                    },
                    {
                        "zoom": 5,
                        "opacity": 0.75
                    },
                    {
                        "zoom": 6,
                        "opacity": 0.75
                    },
                    {
                        "zoom": 7,
                        "opacity": 0.75
                    },
                    {
                        "zoom": 8,
                        "opacity": 0.75
                    },
                    {
                        "zoom": 9,
                        "opacity": 0.75
                    },
                    {
                        "zoom": 10,
                        "opacity": 0.75
                    },
                    {
                        "zoom": 11,
                        "opacity": 0.75
                    },
                    {
                        "zoom": 12,
                        "opacity": 0.75
                    },
                    {
                        "zoom": 13,
                        "opacity": 0.75
                    },
                    {
                        "zoom": 14,
                        "opacity": 0.75
                    },
                    {
                        "zoom": 15,
                        "opacity": 0.75
                    },
                    {
                        "zoom": 16,
                        "opacity": 0.75
                    },
                    {
                        "zoom": 17,
                        "opacity": 0.45
                    },
                    {
                        "zoom": 18,
                        "opacity": 0.45
                    },
                    {
                        "zoom": 19,
                        "opacity": 0.45
                    },
                    {
                        "zoom": 20,
                        "opacity": 0.45
                    },
                    {
                        "zoom": 21,
                        "opacity": 0.45
                    }
                ]
            },
            {
                "tags": "medical",
                "elements": "geometry",
                "stylers": [
                    {
                        "zoom": 0,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 1,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 2,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 3,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 4,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 5,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 6,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 7,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 8,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 9,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 10,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 11,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 12,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 13,
                        "color": "#e5e0dc"
                    },
                    {
                        "zoom": 14,
                        "color": "#e9e5e2"
                    },
                    {
                        "zoom": 15,
                        "color": "#eeeae8"
                    },
                    {
                        "zoom": 16,
                        "color": "#efebe9"
                    },
                    {
                        "zoom": 17,
                        "color": "#efecea"
                    },
                    {
                        "zoom": 18,
                        "color": "#f0ecea"
                    },
                    {
                        "zoom": 19,
                        "color": "#f1edeb"
                    },
                    {
                        "zoom": 20,
                        "color": "#f1eeec"
                    },
                    {
                        "zoom": 21,
                        "color": "#f2efed"
                    }
                ]
            },
            {
                "tags": "beach",
                "elements": "geometry",
                "stylers": [
                    {
                        "zoom": 0,
                        "color": "#e5e0dc",
                        "opacity": 0.3
                    },
                    {
                        "zoom": 1,
                        "color": "#e5e0dc",
                        "opacity": 0.3
                    },
                    {
                        "zoom": 2,
                        "color": "#e5e0dc",
                        "opacity": 0.3
                    },
                    {
                        "zoom": 3,
                        "color": "#e5e0dc",
                        "opacity": 0.3
                    },
                    {
                        "zoom": 4,
                        "color": "#e5e0dc",
                        "opacity": 0.3
                    },
                    {
                        "zoom": 5,
                        "color": "#e5e0dc",
                        "opacity": 0.3
                    },
                    {
                        "zoom": 6,
                        "color": "#e5e0dc",
                        "opacity": 0.3
                    },
                    {
                        "zoom": 7,
                        "color": "#e5e0dc",
                        "opacity": 0.3
                    },
                    {
                        "zoom": 8,
                        "color": "#e5e0dc",
                        "opacity": 0.3
                    },
                    {
                        "zoom": 9,
                        "color": "#e5e0dc",
                        "opacity": 0.3
                    },
                    {
                        "zoom": 10,
                        "color": "#e5e0dc",
                        "opacity": 0.3
                    },
                    {
                        "zoom": 11,
                        "color": "#e5e0dc",
                        "opacity": 0.3
                    },
                    {
                        "zoom": 12,
                        "color": "#e5e0dc",
                        "opacity": 0.3
                    },
                    {
                        "zoom": 13,
                        "color": "#e5e0dc",
                        "opacity": 0.65
                    },
                    {
                        "zoom": 14,
                        "color": "#e9e5e2",
                        "opacity": 1
                    },
                    {
                        "zoom": 15,
                        "color": "#eeeae8",
                        "opacity": 1
                    },
                    {
                        "zoom": 16,
                        "color": "#efebe9",
                        "opacity": 1
                    },
                    {
                        "zoom": 17,
                        "color": "#efecea",
                        "opacity": 1
                    },
                    {
                        "zoom": 18,
                        "color": "#f0ecea",
                        "opacity": 1
                    },
                    {
                        "zoom": 19,
                        "color": "#f1edeb",
                        "opacity": 1
                    },
                    {
                        "zoom": 20,
                        "color": "#f1eeec",
                        "opacity": 1
                    },
                    {
                        "zoom": 21,
                        "color": "#f2efed",
                        "opacity": 1
                    }
                ]
            },
            {
                "tags": {
                    "all": [
                        "is_tunnel",
                        "path"
                    ]
                },
                "elements": "geometry.fill",
                "stylers": [
                    {
                        "color": "#b0a092"
                    },
                    {
                        "opacity": 0.3
                    }
                ]
            },
            {
                "tags": {
                    "all": [
                        "is_tunnel",
                        "path"
                    ]
                },
                "elements": "geometry.outline",
                "stylers": [
                    {
                        "opacity": 0
                    }
                ]
            },
            {
                "tags": "road_limited",
                "elements": "geometry.fill",
                "stylers": [
                    {
                        "color": "#bfb2a6"
                    },
                    {
                        "zoom": 0,
                        "scale": 0
                    },
                    {
                        "zoom": 1,
                        "scale": 0
                    },
                    {
                        "zoom": 2,
                        "scale": 0
                    },
                    {
                        "zoom": 3,
                        "scale": 0
                    },
                    {
                        "zoom": 4,
                        "scale": 0
                    },
                    {
                        "zoom": 5,
                        "scale": 0
                    },
                    {
                        "zoom": 6,
                        "scale": 0
                    },
                    {
                        "zoom": 7,
                        "scale": 0
                    },
                    {
                        "zoom": 8,
                        "scale": 0
                    },
                    {
                        "zoom": 9,
                        "scale": 0
                    },
                    {
                        "zoom": 10,
                        "scale": 0
                    },
                    {
                        "zoom": 11,
                        "scale": 0
                    },
                    {
                        "zoom": 12,
                        "scale": 0
                    },
                    {
                        "zoom": 13,
                        "scale": 0.1
                    },
                    {
                        "zoom": 14,
                        "scale": 0.2
                    },
                    {
                        "zoom": 15,
                        "scale": 0.3
                    },
                    {
                        "zoom": 16,
                        "scale": 0.5
                    },
                    {
                        "zoom": 17,
                        "scale": 0.6
                    },
                    {
                        "zoom": 18,
                        "scale": 0.7
                    },
                    {
                        "zoom": 19,
                        "scale": 0.79
                    },
                    {
                        "zoom": 20,
                        "scale": 0.83
                    },
                    {
                        "zoom": 21,
                        "scale": 0.9
                    }
                ]
            },
            {
                "tags": "road_limited",
                "elements": "geometry.outline",
                "stylers": [
                    {
                        "zoom": 0,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 1,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 2,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 3,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 4,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 5,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 6,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 7,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 8,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 9,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 10,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 11,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 12,
                        "color": "#ffffff",
                        "scale": 1.4
                    },
                    {
                        "zoom": 13,
                        "color": "#ffffff",
                        "scale": 0.1
                    },
                    {
                        "zoom": 14,
                        "color": "#eeebe7",
                        "scale": 0.2
                    },
                    {
                        "zoom": 15,
                        "color": "#eeebe7",
                        "scale": 0.3
                    },
                    {
                        "zoom": 16,
                        "color": "#f0ede9",
                        "scale": 0.5
                    },
                    {
                        "zoom": 17,
                        "color": "#f1eeeb",
                        "scale": 0.6
                    },
                    {
                        "zoom": 18,
                        "color": "#f3f0ee",
                        "scale": 0.7
                    },
                    {
                        "zoom": 19,
                        "color": "#f5f2f0",
                        "scale": 1.18
                    },
                    {
                        "zoom": 20,
                        "color": "#f6f3f1",
                        "scale": 1.23
                    },
                    {
                        "zoom": 21,
                        "color": "#f7f5f3",
                        "scale": 1.33
                    }
                ]
            },
            {
                "tags": {
                    "any": "landcover",
                    "none": "vegetation"
                },
                "stylers": {
                    "visibility": "off"
                }
            }
        ]
        }),
        new ymaps3.YMapDefaultFeaturesLayer({ zIndex: 1800 }),
      ]
    );
  }

  setLocation(center: LngLat, zoom = 15) {
    if (!this.ymap) return;

    this.ymap?.setLocation({
      center,
      zoom,
    });
  }

  async addDefaultMarker(coordinates: LngLat) {
    const { YMapDefaultMarker } = await ymaps3.import(
      "@yandex/ymaps3-markers@0.0.1"
    );
    this.ymap?.addChild(
      new YMapDefaultMarker({
        coordinates,
      })
    );
  }

  addMarker(
    coordinates: LngLat,
    content: HTMLElement,
    props?: Partial<YMapMarkerProps>
  ) {
    if (!this.ymap) return;

    const marker = new ymaps3.YMapMarker(
      {
        coordinates,
        draggable: false,
        ...props,
      },
      content
    );

    this.ymap.addChild(marker);
  }

  addMarkers(coordinates: LngLat[], content: (coords: LngLat) => HTMLElement) {
    coordinates.forEach((coords) => {
      this.addMarker(coords, content(coords));
    });
  }
}
